import { TranslateService } from '@ngx-translate/core';
import { Vendite } from '../interface';
import { AngularFireAuth } from 'angularfire2/auth';
import { FirebaseService } from '../services/firebase.service';
import { PageTitleService } from '../core/page-title/page-title.service';
import { FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as _moment from 'moment';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatTableDataSource,
  MatPaginator,
  MatDatepicker
} from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { PortateHead } from 'app/managemenu/portate/portateHead';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'ms-vendite',
  templateUrl: './vendite.component.html',
  styleUrls: ['./vendite.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class VenditeComponent implements OnInit, AfterViewInit {
  date = new FormControl(moment());
  displayedColumns = ['prodotto', 'quantita'];
  displayedColumnsMens = ['prodotto', 'quantita'];
  dataSource;
  dataSourceMens;
  listPortate = new Array<PortateHead>();

  @ViewChild('paginator')
  paginator: MatPaginator;
  @ViewChild('paginatorMens')
  paginatorMens: MatPaginator;
  currentLang: string;
  start: any;
  stop: any;
  startFilter: any;
  stopFilter: any;

  constructor(
    private pageTitleService: PageTitleService,
    private firebaseService: FirebaseService,
    private afAuth: AngularFireAuth,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle(this.translate.instant('SALES_DESK'));
    this.currentLang = this.translate.currentLang;
  }

  ngAfterViewInit() {
    this.firebaseService
      .getObjCategorieMenuList(this.afAuth.auth.currentUser.uid)
      .then(categories => {
        let listCat = categories.val();
        Object.keys(listCat).forEach(cat => {
          let categoria = listCat[cat].portate;
          Object.keys(categoria).forEach(cat => {
            let portata = new PortateHead();
            portata.uid = categoria[cat].uid;
            portata.descrizione_it = categoria[cat].descrizione_it;
            portata.descrizione_en = categoria[cat].descrizione_en;
            portata.titolo_it = categoria[cat].titolo_it;
            portata.titolo_en = categoria[cat].titolo_en;
            this.listPortate.push(portata);
          });
        });
        this.getStatistiche();
      });

    this.translate.onLangChange.subscribe(() => {
      this.pageTitleService.setTitle(this.translate.instant('SALES_DESK'));
      this.currentLang = this.translate.currentLang;
      this.getStatistiche();
      if (this.start && this.stop) {
        this.showVendite();
      }
    });
  }

  getStatistiche() {
    this.firebaseService
      .getVenditeGiornata(this.afAuth.auth.currentUser.uid)
      .then(stat => {
        let statDett = this.addStatDesc(stat.val());
        this.dataSource = new MatTableDataSource<Vendite>(statDett);
        this.dataSource.paginator = this.paginator;
        this.paginator._intl.itemsPerPageLabel = this.translate.instant(
          'SALES.ROW_PAGE'
        );
      });
  }
  addStatDesc(stat: any): Array<Vendite> {
    if (stat) {
      stat.forEach(s => {
        s.prodotto = this.getDesc(s.uidPietanza);
      });
      return stat;
    }
  }
  getDesc(uidPietanza: any): string {
    let itemIndex = this.listPortate.findIndex(item => item.uid == uidPietanza);
    if (itemIndex != -1)
      return this.currentLang === 'it'
        ? this.listPortate[itemIndex].titolo_it
        : this.listPortate[itemIndex].titolo_en;
  }

  applyFilterStart() {
    let momentDate = this.start;
    let filter = momentDate.format('YYYYMMDD');
    this.startFilter = +filter;
    if (this.stopFilter) {
      this.showVendite();
    }
  }
  showVendite() {
    this.firebaseService
      .getVenditeStorico(this.afAuth.auth.currentUser.uid)
      .then(stat => {
        let res = new Array<Vendite>();
        let statVal = stat.val();

        Object.keys(statVal).forEach(d => {
          if (d >= this.startFilter && d <= this.stopFilter) {
            if (statVal[d].prodotti) {
              let prodList = statVal[d].prodotti;
              prodList.forEach(p => {
                let itemIndex = res.findIndex(
                  item => item.uidPietanza == p.uidPietanza
                );
                if (itemIndex != -1) {
                  res[itemIndex].quantita += p.quantita;
                } else {
                  res.push(p);
                }
              });
            }
          }
        });
        let statDettMens = this.addStatDesc(res);
        this.dataSourceMens = new MatTableDataSource<Vendite>(statDettMens);
        this.dataSourceMens.paginator = this.paginatorMens;
        this.paginatorMens._intl.itemsPerPageLabel = this.translate.instant(
          'SALES.ROW_PAGE'
        );
      });
  }

  applyFilterStop() {
    let momentDate = this.stop;
    let filter = momentDate.format('YYYYMMDD');
    this.stopFilter = +filter;
    if (this.startFilter) {
      this.showVendite();
    }
  }

  removeFilter() {
    this.dataSource.filter = '';
  }
}
