import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const urlGT =
  'https://translation.googleapis.com/language/translate/v2?key=AIzaSyASo68dkjOcsuqkYdrBXlwpXCZddoGIsnE';
@Injectable()
export class GoogleTranslateService {
  constructor(private http: HttpClient) {}

  public translate(targetLang: string, text: string) {
    return this.http
      .get(urlGT + '&target=' + targetLang + '&q=' + text)
      .catch(this.handleError);
  }
  private handleError(error: Response | any) {
    return Observable.throw(error.message || error);
  }
}
