import { Efattura, DatiFatturazione } from './efattura.interface';
import { FirebaseService } from './../services/firebase.service';
import { PageTitleService } from './../core/page-title/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { EFatturaService } from 'app/services/efattura.service';
@Component({
  selector: 'e-fatture-show',
  templateUrl: './efattura.component.html',
  styleUrls: ['./efattura.component.scss']
})
export class EFatturaComponent implements OnInit {
  currentLang: string;
  fattureIn: Array<Efattura>;
  fattureOut: Array<Efattura>;
  datiRisto: any;

  constructor(
    private pageTitleService: PageTitleService,
    private afAuth: AngularFireAuth,
    private firebaseService: FirebaseService,
    private efatturaService: EFatturaService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle(this.translate.instant('SHOW-FATTURE'));
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
      this.pageTitleService.setTitle(this.translate.instant('SHOW-FATTURE'));
    });
    this.firebaseService
      .getDatiFatturazione(this.afAuth.auth.currentUser.uid)
      .then(datiFatt => {
        if (datiFatt.val() != null) {
          this.datiRisto = datiFatt.val();
          this.getFattureIn();
          this.getFattureOut();
        }
      });
  }
  getFattureIn() {
    this.fattureIn = new Array<Efattura>();
    if (this.datiRisto) {
      this.efatturaService
        .getFattureIn(this.datiRisto.userFatturaPA, this.datiRisto.partitaIva)
        .subscribe(res => {
          Object.keys(res).forEach(i => {
            let fatt = {} as Efattura;
            fatt = res[i];
            fatt.downloadPdf = this.efatturaService.getDownloadPdfLink(
              this.datiRisto.userFatturaPA,
              this.datiRisto.partitaIva,
              fatt.filename
            );

            this.fattureIn.push(fatt);
          });
        });
    }
  }
  getFattureOut() {
    this.fattureOut = new Array<Efattura>();
    if (this.datiRisto) {
      this.efatturaService
        .getFattureOut(this.datiRisto.userFatturaPA, this.datiRisto.partitaIva)
        .subscribe(res => {
          Object.keys(res).forEach(i => {
            let fatt = {} as Efattura;
            fatt = res[i];
            fatt.downloadPdf = this.efatturaService.getDownloadPdfLink(
              this.datiRisto.userFatturaPA,
              this.datiRisto.partitaIva,
              fatt.filename
            );

            this.fattureOut.push(fatt);
          });
        });
    }
  }
}
