// The file for the current environment will overwrite this one during build.
// Different environments can be found in ./environment.{dev|prod}.ts, and
// you can create your own and use it with the --env flag.
// The build system defaults to the dev environment.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA_lajuxjhUJvWaYX0IZFfX3FemlKbNWTU',
    authDomain: 'api-5865845063768271021-17389.firebaseapp.com',
    databaseURL: 'https://api-5865845063768271021-17389.firebaseio.com',
    projectId: 'api-5865845063768271021-17389',
    storageBucket: 'api-5865845063768271021-17389.appspot.com',
    messagingSenderId: '417877497356'
  },
  lang: ['it','en']
};
/*firebase: {
    apiKey: 'AIzaSyDveMAKjFiH76sOx1YGwpWrdn5hh1sdZ_o',
    authDomain: 'example-3776a.firebaseapp.com',
    databaseURL: 'https://example-3776a.firebaseio.com',
    projectId: 'example-3776a',
    storageBucket: 'example-3776a.appspot.com',
    messagingSenderId: '576036495724'
  }*/