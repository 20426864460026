import { CustomValidators } from 'ng2-validation';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'ms-login-session',
  templateUrl: './login-component.html',
  styleUrls: ['./login-component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  state: '';
  error: any;
  public form: FormGroup;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.afAuth.authState.subscribe(auth => {
      if (auth) {
        this.router.navigateByUrl('/dashboard');
      }
    });
  }
  ngOnInit() {
    this.form = this.fb.group({
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email])
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(6)])
      ]
    });
  }

  onSubmit() {
    if (this.form.valid) {
      return this.afAuth.auth
        .signInWithEmailAndPassword(
          this.form.value.email,
          this.form.value.password
        )
        .then(success => {
          this.router.navigate(['/dashboard']);
        })
        .catch(err => {
          this.error = err;
        });
    }
  }
  login() {
    this.router.navigate(['/']);
  }
}
