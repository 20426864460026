export class Supplementi {
  uid: string;
  descrizione: string;
  descrizione_it: string;
  descrizione_en: string;
  prezzo: number;

  constructor() {
    this.prezzo = 0;
  }
}
