import { CassaFiscaleComponent } from './cassaFiscale/cassaFiscale.component';
import { SooneatPrintService } from './services/sooneatprint.service';
import { VenditeComponent } from './vendite/vendite.component';
import { BlackListComponent } from './chat/blacklist/blacklist.component';
import { PrinterConnectionModule } from './printerconnection/printerconnection.module';
import { ConfigPrintComponent } from './configprint/configprint.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PrinterService } from './services/printer.service';
import { AbbonamentiComponent } from './abbonamenti/abbonamenti.component';
import { RevenueComponent } from './revenue/revenue.component';
import { CassaComponent } from './cassa/cassa.component';
import { ResetPasswordComponent } from './app-login/reset-password/reset-password.component';
import { LoginComponent } from './app-login/login/login.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule, Http } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from './material.module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import 'hammerjs';

import { AppComponent } from './app.component';
import { AppRoutes } from './app-routing.module';
import { MainComponent } from './main/main.component';
import { MenuToggleModule } from './core/menu/menu-toggle.module';
import { MenuItems } from './core/menu/menu-items/menu-items';
import { HorizontalMenuItems } from './core/menu/menu-items/horizontal-menu-items';
import { PageTitleService } from './core/page-title/page-title.service';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireModule } from 'angularfire2';
import { environment } from 'environments/environment';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { FirebaseService } from './services/firebase.service';
import { AuthGuard } from './services/auth.service';
import {
  CarouselModule,
  TypeaheadModule,
  ModalModule,
  TabsModule
} from 'ngx-bootstrap';
import { CategorieComponent } from './categorie/categorie.component';
import { DownloadpageComponent } from './downloadpage/downloadpage.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleTranslateService } from './services/google-translate.service';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { EFatturaComponent } from './eFattura/efattura.component';
import { EFatturaService } from './services/efattura.service';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule.forRoot(AppRoutes),
    PerfectScrollbarModule,
    MenuToggleModule,
    HttpModule,
    HttpClientModule,
    AngularFireModule.initializeApp(
      environment.firebase,
      'angular-auth-firebase'
    ),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    CarouselModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    NgbModule.forRoot(),
    PrinterConnectionModule,
    NgxImageZoomModule.forRoot(),
    TabsModule.forRoot()
  ],
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    ResetPasswordComponent,
    CassaComponent,
    CassaFiscaleComponent,
    VenditeComponent,
    RevenueComponent,
    AbbonamentiComponent,
    BlackListComponent,
    CategorieComponent,
    ConfigPrintComponent,
    DownloadpageComponent,
    EFatturaComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    MenuItems,
    HorizontalMenuItems,
    PageTitleService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AuthGuard,
    FirebaseService,
    PrinterService,
    SooneatPrintService,
    EFatturaService,
    GoogleTranslateService,
    TranslateService
  ]
})
export class AppModule {}
