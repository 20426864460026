import { Step } from "./step";
import { Supplementi } from "../supplementi/supplementi";
import { Ingredienti } from "../ingredienti/ingredienti";

export class Portate {
  uid: string;
  titolo: string;
  titolo_en: string;
  titolo_it: string;
  descrizione: string;
  descrizione_en: string;
  descrizione_it: string;
  categoria: string;
  prezzo: number;
  prezzobanco: number;
  foto: string;
  image_dettaglio: string;
  ingredienti: Ingredienti[];
  supplementi: Supplementi[];
  step: Step[];
  fuoriMenu: boolean = false;
  custom: boolean = false;

  constructor() {
    this.ingredienti = new Array<Ingredienti>();
    this.supplementi = new Array<Supplementi>();
    this.step = new Array<Step>();
    this.prezzo = 0;
    this.prezzobanco = 0;
  }
}
