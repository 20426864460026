import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
const url = 'http://localhost:8010/';

@Injectable()
export class SooneatPrintService {
  constructor(private http: HttpClient) {}

  public codTavolo(uid: string, lang: string, tav: string, cod: string) {
    this.http
      .get(url + 'codtavolo/' + lang + '/' + tav + '/' + cod)
      .catch(this.handleError)
      .subscribe();
  }

  public comanda(ristoUid: string, lang: string) {
    this.http
      .get(url + 'printorder/' + lang + '/' + ristoUid)
      .catch(this.handleError)
      .subscribe();
  }

  public comandaFF(ristoUid: string, lang: string) {
    this.http
      .get(url + 'orderff/' + lang + '/' + ristoUid)
      .catch(this.handleError)
      .subscribe();
  }

  public contoFF(ristoUid: string, lang: string) {
    this.http
      .get(url + 'printcontoFF/' + lang + '/' + ristoUid)
      .catch(this.handleError)
      .subscribe();
  }

  public contoFiscaleFF(ristoUid: string, lang: string) {
    this.http
      .get(url + 'printcontofiscaleFF/' + lang + '/' + ristoUid)
      .catch(this.handleError)
      .subscribe();
  }

  public conto(
    ristoUid: string,
    lang: string,
    nomeTavolo: string,
    copertiTav: string,
    utenti: string
  ) {
    console.log(url +
    'printconto/' +
    lang +
    '/' +
    ristoUid +
    '/' +
    nomeTavolo +
    '/' +
    copertiTav +
    '/' +
    utenti);

    this.http
      .get(
        url +
          'printconto/' +
          lang +
          '/' +
          ristoUid +
          '/' +
          nomeTavolo +
          '/' +
          copertiTav +
          '/' +
          utenti
      )
      .catch(this.handleError)
      .subscribe();
  }

  public contoFiscale(
    ristoUid: string,
    lang: string,
    nomeTavolo: string,
    copertiTav: string,
    utenti: string
  ) {
    this.http
      .get(
        url +
          'printcontoFiscale/' +
          lang +
          '/' +
          ristoUid +
          '/' +
          nomeTavolo +
          '/' +
          copertiTav +
          '/' +
          utenti
      )
      .catch(this.handleError)
      .subscribe();
  }

  public ristampa(ristoUid: string, lang: string, pinTavolo: string) {
    console.log(
      url + 'comandatavolo/' + lang + '/' + ristoUid + '/' + pinTavolo
    );
    this.http
      .get(url + 'comandatavolo/' + lang + '/' + ristoUid + '/' + pinTavolo)
      .catch(this.handleError)
      .subscribe();
  }

  public check() {
    this.http
      .get(url + 'check')
      .catch(this.handleError)
      .subscribe();
  }

  private handleError(error: Response | any) {
    console.log('ERROR', Observable.throw(error.message || error));
    return Observable.throw(error.message || error);
  }
}
