import { FFOrdini } from './../cassaFiscale/cassaFiscale.component';
import { PortateHead } from '../managemenu/portate/portateHead';
import { BlackList } from './../chat/blacklist/blacklist';
import { Messages, ConfigPrint } from './../interface';
import { Abbonamento } from './../abbonamenti/abbonamento';
import { Table } from '../managetable/table';
import { Portate } from '../managemenu/portate/portate';
import { CategoriaMenu } from '../managemenu/categoriemenu/categoria-menu';
import { Categoria } from '../categorie/categoria';
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Observable } from 'rxjs';
import { Member } from '../manageuser/member';
import { Promozione } from '../promozioni/promozione';
import { Restaurant } from '../managerestaurant/restaurant';
import { Ingredienti } from '../managemenu/ingredienti/ingredienti';
import { Supplementi } from '../managemenu/supplementi/supplementi';
import { PrimoPiano } from '../managerestaurant/primopiano';
import 'rxjs/add/operator/map';
import { StatisticheRisto } from '../managerestaurant/statisticheRisto';
import { StoricoStatisticheRisto } from '../managerestaurant/storicoStatisticheRisto';
import * as _moment from 'moment';
import { DatiFatturazione } from 'app/eFattura/efattura.interface';

@Injectable()
export class FirebaseService {
  private users_path = '/utenti';
  private usersapp_path = '/utentiapp';
  private categorie_path = '/categorie';
  private blacklist_path = '/blacklist';
  private promozioni_path = '/promozioni';
  private restaurant_path = '/ristoranti';
  private menu_path = '/menu';
  private table_path = '/tavoli';
  private ordini_path = '/ordini';
  private cameriere_path = '/cameriere';
  private conto_path = '/richiediconto';
  private statistiche_path = '/statistiche';
  private statistichegiornata_path = '/statisticheGiornata';
  private abbonamenti_path = '/abbonamenti';
  private chat_path = '/chatMessages';
  private config_path = '/configPrint';
  private stampa_ordini = '/stampa_ordini';
  private temp_ff_ordini = '/temp_ff_ordini';
  private ff_ordini = '/ff_ordini';
  private datiFatturazione = '/datiFatturazione';

  constructor(private db: AngularFireDatabase) {}
  // UTILITY
  private toLowerCase(string: string) {
    return string ? string.toLocaleLowerCase() : '';
  }
  private handleError(error) {
    console.log(error);
  }

  // User
  createMember(member: Member): void {
    const uid = member.uid;
    this.db.database.ref(this.users_path + '/' + uid).push(member);
  }

  updateMember(member: Member): void {
    const uid = member.uid;
    member.sort_name = this.toLowerCase(member.name);
    member.sort_lastname = this.toLowerCase(member.lastname);
    member.concatsearch =
      member.name + ' ' + member.lastname + ' - ' + member.email;
    this.db.database
      .ref(this.users_path + '/' + uid)
      .update(member)
      .catch(error => this.handleError(error));
  }

  deleteMember(key: string): void {
    this.db
      .list(this.users_path)
      .remove(key)
      .catch(error => this.handleError(error));
  }

  addKeyAdminMember(uid) {
    this.db.database.ref('/adminMember').update({ [uid]: true });
  }

  removeKeyAdminMember(uid) {
    this.db
      .list('/adminMember')
      .remove(uid)
      .catch(error => this.handleError(error));
  }

  getMembersList(): Observable<Array<Member>> {
    return this.db
      .list(this.users_path, ref => ref.orderByKey())
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({ uid: c.payload.key, ...c.payload.val() }));
      });
  }

  getAdminMembersList(): Observable<any> {
    return this.db
      .list('/adminMember')
      .snapshotChanges()
      .map(actions => {
        return actions.map(a => ({
          key: a.payload.key
        }));
      });
  }
  getMember(uid: string): Observable<any> {
    return this.db.object(this.users_path + '/' + uid).valueChanges();
  }

  getUtente(uid: string) {
    return this.db.database.ref(this.usersapp_path + '/' + uid).once('value');
  }

  getImageUtente(uid: string) {
    this.db.database
      .ref(this.usersapp_path + '/' + uid)
      .once('value')
      .then(function(snapshot) {
        let imageUrl = snapshot.val().nickname;
        return imageUrl;
      });
  }
  getListMemberName(queryText: string): Observable<any> {
    return this.db
      .list(this.users_path, ref =>
        ref
          .orderByChild('sort_name')
          .startAt(queryText)
          .endAt(queryText + '\uf8ff')
      )
      .valueChanges();
  }

  deleteAll(): void {
    this.db
      .list(this.users_path)
      .remove()
      .catch(error => this.handleError(error));
  }

  // Categorie
  addCategory(categoria: Categoria) {
    this.db.database.ref(this.categorie_path).push(categoria);
  }

  updateCategory(categoria: Categoria) {
    this.db.database
      .ref(this.categorie_path + '/' + categoria.uid)
      .update(categoria)
      .catch(error => this.handleError(error));
  }

  deleteCategory(categoriaUid: string) {
    this.db
      .list(this.categorie_path)
      .remove(categoriaUid)
      .catch(error => this.handleError(error));
  }

  getCategoryList(): Observable<Array<Categoria>> {
    return this.db
      .list(this.categorie_path)
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({ uid: c.payload.key, ...c.payload.val() }));
      });
  }

  // PROMOZIONI
  getPromozioniList(): AngularFireList<Member> {
    return this.db.list(this.promozioni_path);
  }

  addPromo(promo: Promozione) {
    this.db.database.ref(this.promozioni_path).push(promo);
  }
  updatePromo(promo: Promozione) {
    this.db.database
      .ref(this.promozioni_path + '/' + promo.uid)
      .update(promo)
      .catch(error => this.handleError(error));
  }
  deletePromo(promo: Promozione) {
    this.db
      .list(this.promozioni_path)
      .remove(promo.uid)
      .catch(error => this.handleError(error));
  }

  getObjPromozioniList() {
    return this.db.database.ref(this.promozioni_path).once('value');
  }

  // RISTORANTE
  getRestaturantList(): Observable<Array<Restaurant>> {
    return this.db
      .list(this.restaurant_path)
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({ uid: c.payload.key, ...c.payload.val() }));
      });
  }

  getRestaurant(uid: string): Observable<any> {
    return this.db.object(this.restaurant_path + '/' + uid).valueChanges();
  }

  getRestaurantVal(uid: string) {
    return this.db.database.ref(this.restaurant_path + '/' + uid).once('value');
  }

  getPinCameriere(uid: string) {
    return this.db.database
      .ref(this.restaurant_path + '/' + uid + '/pinCameriere')
      .once('value');
  }
  setPinCameriere(uid: string, pin: string) {
    return this.db.database
      .ref(this.restaurant_path + '/' + uid + '/pinCameriere')
      .set(pin);
  }

  getDatiFatturazione(uid: string) {
    return this.db.database
      .ref(this.datiFatturazione + '/' + uid)
      .once('value');
  }

  updateDatiFatturazione(datiFatturazione: DatiFatturazione) {
    console.log('DATI_FATT_FB', datiFatturazione);
    this.db.database
      .ref(this.datiFatturazione + '/' + datiFatturazione.uid)
      .update(datiFatturazione)
      .catch(error => this.handleError(error));
  }

  updateRestaurant(restaurant: Restaurant) {
    restaurant.sort_nome = this.toLowerCase(restaurant.nome);
    restaurant.concatsearch = restaurant.nome + ' - ' + restaurant.indirizzo;
    if (restaurant.uid) {
      this.db.database
        .ref(this.restaurant_path + '/' + restaurant.uid)
        .update(restaurant)
        .catch(error => this.handleError(error));
    } else {
      this.db.database.ref(this.restaurant_path).push(restaurant);
    }
  }

  updateRestaurantMenuTrad(uid: string) {
    this.db.database
      .ref(this.restaurant_path + '/' + uid)
      .child('menuTradotto')
      .set(true)
      .catch(error => this.handleError(error));
  }

  deleteRestaurant(restaurant: Restaurant) {
    this.db
      .list(this.restaurant_path)
      .remove(restaurant.uid)
      .catch(error => this.handleError(error));
  }

  // INGREDIENTI
  addIngredienti(ristoUid: string, ingredienti: Ingredienti) {
    this.db.database.ref(this.getIngredientiPath(ristoUid)).push(ingredienti);
  }
  updateIngredienti(ristoUid: string, ingredienti: Ingredienti) {
    this.db.database
      .ref(this.getIngredientiPath(ristoUid) + '/' + ingredienti.uid)
      .update(ingredienti)
      .catch(error => this.handleError(error));
  }

  deleteIngredienti(ristoUid: string, ingredientiUid: string) {
    this.db
      .list(this.getIngredientiPath(ristoUid))
      .remove(ingredientiUid)
      .catch(error => this.handleError(error));
  }
  getIngredientiList(ristoUid: string): Observable<Array<Ingredienti>> {
    return this.db
      .list(this.getIngredientiPath(ristoUid))
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({ uid: c.payload.key, ...c.payload.val() }));
      });
  }
  getObjIngredientiList(ristouid) {
    return this.db.database
      .ref(this.getIngredientiPath(ristouid))
      .once('value');
  }
  private getIngredientiPath(ristoUid: string) {
    const ingredienti_path = '/ingredienti';
    return this.menu_path + '/' + ristoUid + '/' + ingredienti_path;
  }

  // SUPPLEMENTI
  addSupplementi(ristoUid: string, supplementi: Supplementi) {
    this.db.database
      .ref(this.getSupplementiPath(ristoUid))
      .push(supplementi)
      .then(uid => {
        supplementi.uid = uid.key;
        this.db.database
          .ref(this.getSupplementiPath(ristoUid) + '/' + uid.key)
          .update(supplementi)
          .catch(error => this.handleError(error));
      });
  }
  updateSupplementi(ristoUid: string, supplementi: Supplementi) {
    this.db.database
      .ref(this.getSupplementiPath(ristoUid) + '/' + supplementi.uid)
      .update(supplementi)
      .catch(error => this.handleError(error));
  }

  deleteSupplementi(ristoUid: string, supplementiUid: string) {
    this.db
      .list(this.getSupplementiPath(ristoUid))
      .remove(supplementiUid)
      .catch(error => this.handleError(error));
  }
  getSupplementiList(ristoUid: string): Observable<Array<Supplementi>> {
    return this.db
      .list(this.getSupplementiPath(ristoUid))
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({ uid: c.payload.key, ...c.payload.val() }));
      });
  }
  getObjSupplementiList(ristouid) {
    return this.db.database
      .ref(this.getSupplementiPath(ristouid))
      .once('value');
  }

  private getSupplementiPath(ristoUid: string) {
    const supplementi_path = '/supplementi';
    return this.menu_path + '/' + ristoUid + '/' + supplementi_path;
  }

  // CATEGORIE MENU
  addCategorieMenu(ristoUid: string, catmenu: CategoriaMenu) {
    this.db.database
      .ref(this.getCategorieMenuPath(ristoUid))
      .push(catmenu)
      .then(uid => {
        catmenu.uid = uid.key;
        this.db.database
          .ref(this.getCategorieMenuPath(ristoUid) + '/' + uid.key)
          .update(catmenu)
          .catch(error => this.handleError(error));
      });
  }
  updateCategorieMenu(ristoUid: string, catmenu: CategoriaMenu) {
    this.db.database
      .ref(this.getCategorieMenuPath(ristoUid) + '/' + catmenu.uid)
      .update(catmenu)
      .catch(error => this.handleError(error));
  }

  deleteCategorieMenu(ristoUid: string, catUid: CategoriaMenu) {
    this.db
      .list(this.getCategorieMenuPath(ristoUid))
      .remove(catUid.uid)
      .catch(error => this.handleError(error));
  }

  //MENU LIST
  getObjCategorieMenuList(ristouid) {
    return this.db.database
      .ref(this.getCategorieMenuPath(ristouid))
      .once('value');
  }

  getCategorieMenuList(ristoUid: string): Observable<Array<CategoriaMenu>> {
    return this.db
      .list(this.getCategorieMenuPath(ristoUid), ref =>
        ref.orderByChild('posizione')
      )
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({ uid: c.payload.key, ...c.payload.val() }));
      });
  }
  getStaticCategorieMenu(ristoUid: string) {
    return this.db.database
      .ref(this.getCategorieMenuPath(ristoUid))
      .once('value');
  }

  // PORTATE
  addPortata(ristoUid: string, categoriaUid: string, portata: Portate) {
    return this.db.database
      .ref(this.getPortataPath(ristoUid, categoriaUid) + '/')
      .push(portata);
  }
  updatePortata(ristoUid: string, categoriaUid: string, portata: Portate) {
    this.db.database
      .ref(this.getPortataPath(ristoUid, categoriaUid) + '/' + portata.uid)
      .update(portata)
      .catch(error => this.handleError(error));
  }

  deletePortata(ristoUid: string, categoriaUid: string, uidPortata: string) {
    return this.db
      .list(this.getPortataPath(ristoUid, categoriaUid))
      .remove(uidPortata);
  }

  // PRIMO PIANO
  getPrimoPianoList(ristoUid: string) {
    return this.db.list(this.restaurant_path + '/' + ristoUid + '/primoPiano');
  }

  getObjRistoList() {
    return this.db.database.ref(this.restaurant_path).once('value');
  }

  addPrimoPiano(ristoUid: string, primopiano: PrimoPiano) {
    this.db.database
      .ref(this.restaurant_path + '/' + ristoUid + '/primoPiano')
      .push(primopiano);
  }

  updatePrimoPiano(ristoUid: string, primopiano: PrimoPiano) {
    this.db.database
      .ref(
        this.restaurant_path + '/' + ristoUid + '/primoPiano/' + primopiano.uid
      )
      .update(primopiano)
      .catch(error => this.handleError(error));
  }

  deletePrimoPiano(ristoUid: string, primopiano: PrimoPiano) {
    this.db
      .list(this.restaurant_path + '/' + ristoUid + '/primoPiano')
      .remove(primopiano.uid)
      .catch(error => this.handleError(error));
  }

  private getCategorieMenuPath(ristoUid: string) {
    const catmenu_path = 'categorie';
    return this.menu_path + '/' + ristoUid + '/' + catmenu_path;
  }

  private getPortataPath(ristoUid: string, categoriaUid: string) {
    const catmenu_path = '/categorie';
    return (
      this.menu_path +
      '/' +
      ristoUid +
      catmenu_path +
      '/' +
      categoriaUid +
      '/portate'
    );
  }

  //MENU
  getMenu(ruid: string) {
    return this.db.list('menu/' + ruid + '/categorie/', ref =>
      ref.orderByChild('posizione')
    );
  }

  getObjMenu(ristouid) {
    return this.db.database
      .ref('menu/' + ristouid + '/categorie/')
      .once('value');
  }
  // TAVOLI
  getTableList(ruid: string): Observable<Array<Table>> {
    return this.db
      .list(this.table_path + '/' + ruid, ref =>
        ref.orderByChild('progressivo')
      )
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({ uid: c.payload.key, ...c.payload.val() }));
      });
  }

  getTable(ruid: string, tuid: string) {
    return this.db
      .object(this.table_path + '/' + ruid + '/' + tuid)
      .valueChanges();
  }

  openTable(ristoUid: string, table: Table) {
    return this.db.database
      .ref(this.table_path)
      .child(ristoUid)
      .child(table.pin)
      .set(table);
  }

  updateTable(ristoUid: string, table: Table) {
    return this.db.database
      .ref(this.table_path + '/' + ristoUid + '/' + table.pin)
      .update(table)
      .catch(error => this.handleError(error));
  }
  updatePropertyTable(ristoUid: string, table: Table, property: string) {
    return this.db.database
      .ref(this.table_path + '/' + ristoUid + '/' + table.pin)
      .child(property)
      .set(property == 'pax' ? Number(table[property]) : table[property])
      .catch(error => this.handleError(error));
  }

  updateCopertoPagatoTable(ristoUid: string, table: Table) {
    return this.db.database
      .ref(this.table_path + '/' + ristoUid + '/' + table.pin)
      .child('copertoPagatoCassa')
      .set(Number(table['copertoPagatoCassa']))
      .catch(error => this.handleError(error));
  }

  closeTable(ristoUid: string, table: Table) {
    return this.db.database
      .ref(this.table_path + '/' + ristoUid + '/' + table.uid)
      .set(table)
      .catch(error => this.handleError(error));
  }

  deleteTable(ristoUid: string, uidTable: string) {
    return this.db
      .list(this.table_path + '/' + ristoUid)
      .remove(uidTable)
      .catch(error => this.handleError(error));
  }

  //ORDINI
  getAllOrderList(uidRisto: string) {
    return this.db
      .list(this.ordini_path + '/' + uidRisto)
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({ uid: c.payload.key, ...c.payload.val() }));
      });
  }

  getComandaList(uidRisto: string) {
    return this.db.database
      .ref(this.ordini_path + '/' + uidRisto)
      .once('value');
  }

  async setStampato(
    uidRisto: string,
    uidUtente: string,
    uidOrderEntry: string
  ) {
    let stampato = { stampato: true };
    try {
      return this.db.database
        .ref(
          this.ordini_path +
            '/' +
            uidRisto +
            '/' +
            uidUtente +
            '/orderEntryList/' +
            uidOrderEntry
        )
        .child('stampato')
        .set(true);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async setPagCassa(uidRisto: string, uidUtente: string, property: string) {
    try {
      return this.db.database
        .ref(this.ordini_path + '/' + uidRisto + '/' + uidUtente)
        .child(property)
        .set(true);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async setValPagCassa(
    uidRisto: string,
    uidUtente: string,
    property: string,
    value
  ) {
    try {
      return this.db.database
        .ref(this.ordini_path + '/' + uidRisto + '/' + uidUtente)
        .child(property)
        .set(value);
    } catch (error) {
      return this.handleError(error);
    }
  }

  getOrderList(uidRisto: string, uidUtente): Observable<any> {
    return this.db
      .object(this.ordini_path + '/' + uidRisto + '/' + uidUtente)
      .valueChanges();
  }

  updateOrderPrice(uidRisto: string, utente: string, prezzoList: number) {
    return this.db.database
      .ref(this.ordini_path + '/' + uidRisto + '/' + utente)
      .child('prezzoTotale')
      .set(prezzoList)
      .catch(error => this.handleError(error));
  }

  deleteOrderEntry(uidRisto: string, utente: string, entry: string) {
    return this.db
      .list(
        this.ordini_path + '/' + uidRisto + '/' + utente + '/orderEntryList'
      )
      .remove(entry)
      .catch(error => this.handleError(error));
  }

  removeOrdini(rid: string, utente: string, valutazioni: boolean) {
    this.db
      .list(this.ordini_path + '/' + rid)
      .remove(utente)
      .catch(error => this.handleError(error));
    if (valutazioni)
      this.db.database
        .ref(this.usersapp_path)
        .child(utente)
        .child('valutazioni')
        .set(rid)
        .catch(error => this.handleError(error));
  }

  valutazioniUtente(rid: string, utente: string) {
    this.db.database
      .ref(this.usersapp_path)
      .child(utente)
      .child('valutazioni')
      .set(rid)
      .catch(error => this.handleError(error));
  }

  // CAMERIERE
  cameriere(ristoId: string): Observable<any> {
    return this.db
      .list(this.cameriere_path + '/' + ristoId)
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({ uid: c.payload.key, ...c.payload.val() }));
      });
  }

  evadiChiamataCameriere(ristoId: string, callId: string) {
    this.db
      .list(this.cameriere_path + '/' + ristoId)
      .remove(callId)
      .catch(error => this.handleError(error));
  }
  evadiAllChiamataCameriere(ristoId: string) {
    return this.db.list(this.cameriere_path).remove(ristoId);
  }

  // CONTO
  conto(ristoId: string): Observable<any> {
    return this.db
      .list(this.conto_path + '/' + ristoId)
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({ uid: c.payload.key, ...c.payload.val() }));
      });
  }

  evadiChiamataConto(ristoId: string, callId: string) {
    this.db
      .list(this.conto_path + '/' + ristoId)
      .remove(callId)
      .catch(error => this.handleError(error));
  }

  evadiAllChiamataConto(ristoId: string) {
    return this.db.list(this.conto_path).remove(ristoId);
  }

  deleteContoAllTable(ristoId: string, tableId: string) {
    this.db.database
      .ref(this.conto_path + '/' + ristoId)
      .once('value')
      .then(snap => {
        let conto = snap.val();
        if (conto) {
          Object.keys(conto).forEach(key => {
            if (conto[key].tavolo === tableId) {
              this.evadiChiamataConto(ristoId, key);
            }
          });
        }
      });
  }

  // STATISTICHE

  getStatisticheCassa(uid: string) {
    return this.db
      .list(this.statistiche_path + '/' + uid)
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({
          data: _moment(c.payload.key, 'YYYYMMDD').format('DD.MM.YYYY'),
          coperti: c.payload.val().numClientiRistorante,
          carta: Number(c.payload.val().totaleCarta),
          contanti: Number(
            Number(c.payload.val().totaleGiorno.toFixed(2)) -
              Number(c.payload.val().totaleCarta.toFixed(2)) - (c.payload.val().contantiBanco
              ? Number(c.payload.val().contantiBanco.toFixed(2))
              : 0) - (c.payload.val().cartaBanco
              ? Number(c.payload.val().cartaBanco.toFixed(2))
              : 0)
          ).toFixed(2),
          totale: Number(c.payload.val().totaleGiorno),
          banco: c.payload.val().contantiBanco
            ? Number(c.payload.val().contantiBanco.toFixed(2))
            : 0,
          ccBanco: c.payload.val().cartaBanco
            ? Number(c.payload.val().cartaBanco.toFixed(2))
            : 0
        }));
      });
  }

  getVenditeGiornata(uid: string) {
    return this.db.database
      .ref(this.statistichegiornata_path + '/' + uid)
      .child('prodotti')
      .once('value');
  }

  getVenditeStorico(uid: string) {
    return this.db.database
      .ref(this.statistiche_path + '/' + uid)
      .once('value');
  }

  getStatisticheStorico(uid: string, dataStat: string) {
    return this.db.database
      .ref(this.statistiche_path + '/' + uid)
      .child(dataStat)
      .once('value');
  }

  addStatisticheStorico(uid: string, dataStat: string) {
    let statStorico: StoricoStatisticheRisto;
    return this.getStatisticheGiornata(uid).then(statGiorno => {
      statStorico = statGiorno.val();
      if (statStorico.dataStat) {
        this.updateStatisticheStorico(
          uid,
          statStorico.dataStat,
          statStorico
        ).then(() => {
          this.deleteChatMessage(uid);
          this.deleteStampaOrdini(uid);
          return this.resetStatisticheGiornata(uid, dataStat);
        });
      }
    });
  }

  updateStatisticheStorico(
    uid: string,
    dataStat: string,
    stat: StoricoStatisticheRisto
  ) {
    return this.db.database
      .ref(this.statistiche_path + '/' + uid + '/' + dataStat)
      .update(stat)
      .catch(error => this.handleError(error));
  }

  // STATISTICHE GIORNATA
  getStatisticheGiornata(uid: string) {
    return this.db.database
      .ref(this.statistichegiornata_path)
      .child(uid)
      .once('value');
  }

  getStatisticheGiornataListner(uid: string) {
    return this.db
      .object(this.statistichegiornata_path + '/' + uid)
      .snapshotChanges();
  }

  addStatisticheGiornata(
    uid: string,
    statistiche: StatisticheRisto,
    dataStat: string
  ) {
    let stat = statistiche ? statistiche : new StatisticheRisto();
    if (dataStat) stat.dataStat = dataStat;
    this.getTableList(uid).subscribe(tav => {
      let countOpen = 0;
      let countClientSeat;
      tav.forEach(t => {
        if (t.open) {
          countOpen++;
          if (t.clienti) {
            if (countClientSeat)
              countClientSeat = Object.assign(countClientSeat, t.clienti);
            else countClientSeat = Object.assign(t.clienti);
          }
        }
      });
      stat.tavoliAperti = countOpen;
      if (countClientSeat) {
        stat.clientiInSala = countClientSeat;
        this.updateStatisticheGiornata(uid, stat);
      } else {
        this.removeClientiStatisticheGiornata(uid).then(() => {
          stat.clientiInSala = null;
          this.updateStatisticheGiornata(uid, stat);
        });
      }
    });
  }

  updateStatisticheGiornata(uid: string, stat: StatisticheRisto) {
    return this.db.database
      .ref(this.statistichegiornata_path + '/' + uid)
      .update(stat)
      .catch(error => this.handleError(error));
  }

  removeClientiStatisticheGiornata(uid: string) {
    return this.db
      .list(this.statistichegiornata_path + '/' + uid)
      .remove('clientiInSala')
      .catch(error => this.handleError(error));
  }

  resetStatisticheGiornata(uid, dataStat) {
    let stat = new StatisticheRisto();
    stat.dataStat = dataStat;
    stat.chiamateCameriere = 0;
    stat.chiamateCameriere = 0;
    stat.tavoliAperti = 0;
    stat.utentiChat = 0;
    stat.totaleGiorno = 0;
    stat.totaleCarta = 0;
    stat.numClientiInSala = 0;
    stat.numClientiRistorante = 0;

    return this.db.database
      .ref(this.statistichegiornata_path + '/' + uid)
      .set(stat)
      .catch(error => this.handleError(error));
  }

  // ABBONAMENTI
  getAbbonamentiList() {
    return this.db
      .list(this.abbonamenti_path)
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({
          key: c.payload.key,
          ...c.payload.val()
        }));
      });
  }

  getAbbonamento(abbonamentoKey: string) {
    return this.db.database
      .ref(this.abbonamenti_path + '/' + abbonamentoKey)
      .once('value');
  }

  getAbbonamenti() {
    return this.db.database.ref(this.abbonamenti_path).once('value');
  }

  addAbbonamenti(abbonamento: Abbonamento) {
    this.db.database.ref(this.abbonamenti_path).push(abbonamento);
  }

  updateAbbonamenti(key: string, abbonamento: Abbonamento) {
    this.db.database
      .ref(this.abbonamenti_path + '/' + key)
      .update(abbonamento)
      .catch(error => this.handleError(error));
  }

  deleteAbbonamenti(key: string) {
    this.db
      .list(this.abbonamenti_path)
      .remove(key)
      .catch(error => this.handleError(error));
  }

  // CHAT
  getChatMessages(uid: string) {
    return this.db
      .list(this.chat_path + '/' + uid + '/general/messages')
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({
          ...c.payload.val()
        }));
      });
  }
  addChatMessage(uid: string, message: Messages) {
    this.db.database
      .ref(this.chat_path + '/' + uid + '/general/messages')
      .push(message);
  }

  deleteChatMessagesUser(ruid: string, uidUtente: string): any {
    this.db
      .list(this.chat_path + '/' + ruid)
      .remove(uidUtente)
      .catch(error => this.handleError(error));
    this.db
      .list(this.chat_path + '/' + ruid + '/general/members')
      .remove(uidUtente)
      .catch(error => this.handleError(error));
  }

  deleteChatMessage(ruid: string) {
    this.db
      .list(this.chat_path)
      .remove(ruid)
      .catch(error => this.handleError(error));
  }

  deleteStampaOrdini(ruid: string) {
    this.db
      .list(this.stampa_ordini)
      .remove(ruid)
      .catch(error => this.handleError(error));
  }

  deleteChatPrivateMessages(ruid: string, userid: string) {
    this.db.database
      .ref(this.chat_path + '/' + ruid)
      .once('value')
      .then(snap => {
        if (snap.val()) {
          Object.keys(snap.val()).forEach(key => {
            if (key.includes(userid)) {
              this.db.list(this.chat_path + '/' + ruid).remove(key);
              this.db.list('/userChats/').remove(userid);

              const usersList = key.split('|');
              if (usersList) {
                if (usersList[0] != userid) {
                  this.db
                    .list('/userChats/' + usersList[0])
                    .remove(usersList[1]);
                } else {
                  this.db
                    .list('/userChats/' + usersList[1])
                    .remove(usersList[0]);
                }
              }
            }
          });
        }
      });
  }

  // CHAT BLACKLIST

  addBlackList(bl: Array<string>) {
    this.db.database
      .ref(this.blacklist_path)
      .set(bl)
      .catch(error => this.handleError(error));
  }

  updateBlackList(bl: Array<string>) {
    this.db.database
      .ref(this.blacklist_path)
      .set(bl)
      .catch(error => this.handleError(error));
  }

  getBlackList() {
    return this.db.database.ref(this.blacklist_path).once('value');
  }

  // REVENUE
  getCompanyList() {
    return this.db.database.ref(this.restaurant_path).once('value');
  }
  // CONFIG

  getConfigList(ruid: string) {
    return this.db
      .list(this.config_path + '/' + ruid)
      .snapshotChanges()
      .map(changes => {
        return changes.map(c => ({
          key: c.payload.key,
          ...c.payload.val()
        }));
      });
  }
  addConfig(ruid: string, conf: ConfigPrint) {
    return this.db.database.ref(this.config_path + '/' + ruid).push(conf);
  }
  updateConfig(ruid: string, conf: ConfigPrint) {
    this.db.database
      .ref(this.config_path + '/' + ruid)
      .update(conf)
      .catch(error => this.handleError(error));
  }

  deleteConfig(ruid: string, uidConf: string) {
    this.db
      .list(this.config_path + '/' + ruid)
      .remove(uidConf)
      .catch(error => this.handleError(error));
  }

  // ORDINI_FF
  addTempFFOrdini(ruid: string, orderList: FFOrdini) {
    return this.db.database
      .ref(this.temp_ff_ordini + '/' + ruid)
      .push(orderList);
  }

  addFFOrdini(ruid: string, orderList: FFOrdini) {
    return this.getFFOrder(ruid).then(res => {
      let arrayFFord: Array<FFOrdini>;
      if (res.val()) {
        arrayFFord = res.val();
        arrayFFord[0] = orderList;
      } else {
        arrayFFord = new Array<FFOrdini>();
        arrayFFord.push(orderList);
      }

      this.db.database
        .ref(this.ff_ordini + '/' + ruid + '/orderList')
        .set(arrayFFord);
    });
  }
  getMaxOrder(ruid: string) {
    return this.db.database
      .ref(this.ff_ordini + '/' + ruid + '/progOrder')
      .once('value');
  }

  getFFOrder(ruid: string) {
    return this.db.database
      .ref(this.ff_ordini + '/' + ruid + '/orderList')
      .once('value');
  }

  getTempFFOrdini(ruid: string) {
    console.log('getTempFFOrdini', this.temp_ff_ordini + '/' + ruid);
    return this.db.database
      .ref(this.temp_ff_ordini + '/' + ruid)
      .once('value')
      .then(snap => {
        if (snap.val() == null) {
          return this.db.database
            .ref(this.ff_ordini + '/' + ruid + '/orderList')
            .once('value');
        } else {
          return this.db.database
            .ref(this.temp_ff_ordini + '/' + ruid)
            .once('value');
        }
      });
  }

  deleteTempFFOrdini(ruid: string, uidOrd: string) {
    console.log('DELETE TEMP ORDER');
    this.db
      .list(this.temp_ff_ordini + '/' + ruid)
      .remove(uidOrd)
      .catch(error => this.handleError(error));
  }
  closeFF(ruid: string, maxOrder: number) {
    this.db.database
      .ref(this.ff_ordini + '/' + ruid)
      .child('progOrder')
      .set(maxOrder)
      .catch(error => this.handleError(error))
      .then(() => {
        this.db
          .list(this.ff_ordini + '/' + ruid)
          .remove('orderList')
          .catch(error => this.handleError(error));
      });
  }
}
