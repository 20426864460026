export class Promozione {
  uid: string;
  photoUrl: string;
  titolo: string;
  titolo_it: string;
  titolo_en: string;
  descrizione: string;
  descrizione_it: string;
  descrizione_en: string;
  externalUrl:string;
}
