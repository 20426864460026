import { Component, Optional, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MatSnackBar
} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  constructor(private router: Router, public translate: TranslateService) {
    translate.addLangs(['en', 'fr', 'it']);
    translate.setDefaultLang('it');

    const browserLang: string = translate.getBrowserLang();
    console.log(browserLang);
    translate.use(browserLang);
  }

  /* These following methods used for theme preview, you can remove this methods */

  ngOnInit() {}
}
