export class Ingredienti {
  uid: string;
  descrizione: string;
  descrizione_it: string;
  descrizione_en: string;
  bloccato: boolean;

  constructor() {
    this.bloccato = false;
  }
}
