import { SooneatPrintService } from './../services/sooneatprint.service';
import { TranslateService } from '@ngx-translate/core';
import { Categoria } from './../categorie/categoria';
import { ConfigPrint } from './../interface';
import { CategoriaMenu } from './../managemenu/categoriemenu/categoria-menu';
import { PageTitleService } from './../core/page-title/page-title.service';
import { FirebaseService } from './../services/firebase.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'ms-configprint',
  templateUrl: './configprint.component.html',
  styleUrls: ['./configprint.component.scss']
})
export class ConfigPrintComponent implements OnInit {
  currentLang: any;
  fileConfig: any;
  textAreaRows: number;
  textAreaCol: any;
  constructor(
    private pageTitleService: PageTitleService,
    private firebaseService: FirebaseService,
    private afAuth: AngularFireAuth,
    private translate: TranslateService,
    private sooneatService: SooneatPrintService
  ) {}
  printerActive: boolean = false;
  waitConnector: boolean = false;
  categorieList: CategoriaMenu[];
  printerList;
  error: string;
  printerSelect: string;
  categorySelected: Categoria;

  configList: ConfigPrint[];
  configListCat: ConfigPrint[];
  configDefault: ConfigPrint;

  ngOnInit() {
    this.pageTitleService.setTitle(this.translate.instant('PRINT_MANAGEMENT'));
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
      this.pageTitleService.setTitle(
        this.translate.instant('PRINT_MANAGEMENT')
      );
    });
    this.getCategorieMenu(this.afAuth.auth.currentUser.uid);
  }

  getCategorieMenu(uuid: string) {
    this.firebaseService.getStaticCategorieMenu(uuid).then(categorie => {
      this.fileConfig = 'default=nomeStampante\r\n';
      this.textAreaRows = 0;
      Object.keys(categorie.val()).forEach(element => {
        this.textAreaRows += 1;
        let row =
          'nomeStampante=' +
          element +
          '#' +
          (this.currentLang === 'it'
            ? categorie.val()[element].descrizione_it
            : categorie.val()[element].descrizione_en) +
          '\r\n';
        this.fileConfig += row;
        this.textAreaCol =
          row.length > this.textAreaCol ? row.length : this.textAreaCol;
      });
    });
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  checkPrint() {
    this.sooneatService.check();
  }
}
