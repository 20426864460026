import { FirebaseService } from './../../services/firebase.service';
import { PageTitleService } from './../../core/page-title/page-title.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss']
})
export class BlackListComponent implements OnInit {
  blacklist: string;
  searchItem: string;
  blacklistSelected: string;
  blacklistArray;
  currentLang: string;
  constructor(
    private pageTitleService: PageTitleService,
    private firebaseService: FirebaseService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.pageTitleService.setTitle('BLACK LIST');
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
      this.getBlackList();
    });
    this.getBlackList();
  }

  getBlackList() {
    this.firebaseService.getBlackList().then(bl => {
      this.blacklistArray = bl.val();
    });
  }

  deleteBlacklist(blDel: string) {
    const confirmMessage_it =
      'Sei sicuro di voler cancellare la parola ' + blDel;
    const confirmMessage_en =
      'Are you sure you want to delete the word' + blDel;
    const confirmMes: string =
      this.currentLang === 'it' ? confirmMessage_it : confirmMessage_en;
    if (confirm(confirmMes)) {
      this.blacklistArray.splice(this.blacklistArray.indexOf(blDel), 1);
      this.firebaseService.updateBlackList(this.blacklistArray);
    }
  }

  blTHOnSelect($event) {
    this.blacklistSelected = $event.item; // selected object is $event.item
  }

  showAll() {
    this.blacklistSelected = undefined;
    this.searchItem = undefined;
  }

  onSubmit() {
    if (!this.blacklistArray) {
      this.blacklistArray = new Array<string>();
    }
    this.blacklistArray.push(this.blacklist.toLowerCase());
    this.firebaseService.addBlackList(this.blacklistArray);
    this.blacklist = undefined;
  }
}
