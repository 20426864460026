import { Injectable } from '@angular/core';

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  role: string[];
  icon: string;
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'dashboard',
    name: 'HOME',
    type: 'link',
    role: ['amministratore', 'ristoratore', 'editore'],
    icon: 'home'
  },
  {
    state: 'manageuser',
    name: 'MANAGE_USER',
    type: 'sub',
    role: ['amministratore'],
    icon: 'camera_front',
    children: [
      { state: 'alluser', name: 'USERS', role: ['amministratore'] },
      { state: 'allrestaurant', name: 'RESTAURANT', role: ['amministratore'] },
      { state: 'newuser', name: 'ADD_USER', role: ['amministratore'] }
    ]
  },
  {
    state: 'managetable',
    name: 'MANAGE_TABLE',
    type: 'link',
    role: ['ristoratore'],
    icon: 'apps'
  },
  {
    state: 'cassaFiscale',
    name: 'FISCAL_CASH_DESK',
    type: 'link',
    role: ['ristoratore'],
    icon: 'attach_money'
  },
  {
    state: 'managemenu',
    name: 'MANAGE_MENU',
    type: 'sub',
    role: ['amministratore', 'ristoratore', 'editore'],
    icon: 'restaurant_menu',
    children: [
      {
        state: 'lista',
        name: 'SHOW_LIST',
        role: ['amministratore', 'ristoratore', 'editore']
      },
      {
        state: 'inserisci',
        name: 'INSERT',
        role: ['amministratore', 'ristoratore', 'editore']
      },
      {
        state: 'categoriemenu',
        name: 'MENU_CAT',
        role: ['amministratore', 'editore']
      },
      {
        state: 'supplementi',
        name: 'SUPPLEMENTS',
        role: ['amministratore', 'ristoratore', 'editore']
      }
    ]
  },
  {
    state: 'promozioni',
    name: 'PROMO',
    type: 'sub',
    role: ['amministratore', 'ristoratore'],
    icon: 'local_offer',
    children: [
      { state: 'promohome', name: 'PROMO_HOME', role: ['amministratore'] },
      /*      {
        state: 'chefConsiglia',
        name: 'Chef Consiglia',
        role: ['amministratore', 'ristoratore']
      },
      */
      {
        state: 'primoPiano',
        name: 'FIRST_FLOOR',
        role: ['amministratore', 'ristoratore']
      }
    ]
  },
  {
    state: 'classification',
    name: 'CLASSIFICATION',
    type: 'link',
    role: ['amministratore'],
    icon: 'assignment'
  },
  {
    state: 'abbonamenti',
    name: 'ABBONNEMENTS',
    type: 'link',
    role: ['amministratore'],
    icon: 'business'
  },
  {
    state: 'blacklist',
    name: 'Chat BlackList',
    type: 'link',
    role: ['amministratore'],
    icon: 'apps'
  },
  {
    state: 'chat',
    name: 'CHAT',
    type: 'link',
    role: ['ristoratore'],
    icon: 'chat_bubble_outline'
  },
  {
    state: 'reports',
    name: 'REPORTS',
    type: 'sub',
    role: ['amministratore', 'ristoratore'],
    icon: 'timeline',
    children: [
      { state: 'revenue', name: 'REVENUE', role: ['amministratore'] },
      { state: 'cassa', name: 'CASH_DESK', role: ['ristoratore'] },
      { state: 'vendite', name: 'SALES_DESK', role: ['ristoratore'] },
      { state: 'efatture', name: 'SHOW-FATTURE', role: ['ristoratore'] }
    ]
  },
  {
    state: 'settings',
    name: 'SETTINGS',
    type: 'sub',
    role: ['amministratore', 'ristoratore', 'nuovoutente', 'editore'],
    icon: 'settings',
    children: [
      { state: 'myristo', name: 'MANAGE_COMPANY', role: ['ristoratore'] },
      { state: 'configprint', name: 'PRINT_MANAGEMENT', role: ['ristoratore'] },
      { state: 'download', name: 'DOWN_CONN', role: ['ristoratore'] },
      {
        state: 'accountinfo',
        name: 'PERSONAL_DATA',
        role: ['amministratore', 'ristoratore', 'nuovoutente', 'editore']
      }
    ]
  }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
