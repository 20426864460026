import { StatisticheProdotti } from './../managerestaurant/statisticheProdotti';
import { StatisticheRisto } from './../managerestaurant/statisticheRisto';
import { SooneatPrintService } from './../services/sooneatprint.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { FirebaseService } from '../services/firebase.service';
import { PageTitleService } from '../core/page-title/page-title.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'ms-cassa-fiscale',
  templateUrl: './cassaFiscale.component.html',
  styleUrls: ['./cassaFiscale.component.scss']
})
export class CassaFiscaleComponent implements OnInit, OnDestroy {
  categorieMenu: Array<MenuFis>;
  currentLang: string;
  catSelMenu: string;
  uidCatBanco: string;
  orderList: Array<ScontrinoFis>;
  prezzoTotale: number = 0;
  openQuantita: boolean = false;
  currentModIng: Array<IngMod>;
  currentModSup: Array<SupMod>;
  currentModNota: string;
  currentModIndex: string;
  progressivoPie: number = 0;
  calcolatrice: boolean = false;
  chiudiscontrino: boolean = false;
  catBanco: MenuFis;
  maxOrder: number = 0;
  pagamento: string;
  sconto: number;
  varie: string = '';
  currentModStepSup: StepMod[];
  dc: boolean;
  impSconto: number = 0;
  percSconto: number = 0;
  totaleScontrino: number;
  contanti: boolean = true;
  carta: boolean = false;
  enableInviaComanda: boolean = false;
  contantivers: number;
  
  constructor(
    private modalService: NgbModal,
    private pageTitleService: PageTitleService,
    private firebaseService: FirebaseService,
    private afAuth: AngularFireAuth,
    public translate: TranslateService,
    private sooneatService: SooneatPrintService
  ) {}

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.pageTitleService.setTitle(this.translate.instant('FISCAL_CASH_DESK'));
    this.getCategorieMenu();
    this.getTempOrder();
    this.translate.onLangChange.subscribe(() => {
      this.pageTitleService.setTitle(
        this.translate.instant('FISCAL_CASH_DESK')
      );
      this.currentLang = this.translate.currentLang;
      this.getCategorieMenu();
      this.getTempOrder();
    });
  }

  getTempOrder() {
    this.firebaseService
      .getTempFFOrdini(this.afAuth.auth.currentUser.uid)
      .then(snap => {
        if (snap.val()) {
          console.log('SNAP', snap.val());
          Object.keys(snap.val()).forEach(key => {
            let ffOrdini = snap.val()[key];
            this.prezzoTotale = ffOrdini.totale;
            this.pagamento = ffOrdini.pagamento;
            this.sconto = ffOrdini.sconto;
            this.orderList = ffOrdini.orderEntryList;
            this.checkInviaComanda();
            this.prezzoTotale = ffOrdini.prezzo;
            this.impSconto = ffOrdini.sconto;
            this.percSconto = ffOrdini.percSonto;
            this.totaleScontrino = this.prezzoTotale - this.impSconto;
            this.contanti = ffOrdini.pagamento === 'C';
            this.carta = ffOrdini.pagamento === 'CC';
            this.firebaseService.deleteTempFFOrdini(
              this.afAuth.auth.currentUser.uid,
              key
            );
          });
        }
      });
    this.getMaxOrder();
  }

  private checkInviaComanda() {
    this.enableInviaComanda = false;
    if (this.orderList) {
      this.orderList.forEach(order => {
        if (order.pietanza.categoria != this.uidCatBanco) {
          this.enableInviaComanda = true;
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.orderList) {
      let ffOrdini = {} as FFOrdini;
      ffOrdini.numeroOrdine = this.maxOrder;
      ffOrdini.prezzo = this.prezzoTotale;
      ffOrdini.pagamento = this.contanti ? 'C' : 'CC';
      ffOrdini.sconto = this.sconto ? this.sconto : 0;
      ffOrdini.totaleScontrino = this.prezzoTotale - ffOrdini.sconto;
      ffOrdini.percSonto = this.percSconto;
      ffOrdini.orderEntryList = this.orderList;
      console.log('FFORDINI', ffOrdini);
      this.firebaseService.addTempFFOrdini(
        this.afAuth.auth.currentUser.uid,
        ffOrdini
      );
    }
  }

  getCategorieMenu() {
    this.categorieMenu = new Array<MenuFis>();
    this.firebaseService
      .getStaticCategorieMenu(this.afAuth.auth.currentUser.uid)
      .then(snap => {
       
        let snapVal = snap.val();
        console.log("SNAPVAL",snapVal);
        Object.keys(snapVal).forEach(element => {
          
          let categoriaDb = snapVal[element];
          console.log("CAT",categoriaDb);
          let categoria = {} as MenuFis;
          categoria.descrizione_it = categoriaDb.descrizione_it;
          categoria.descrizione_en = categoriaDb.descrizione_en;
          categoria.posizione = categoriaDb.posizione;
          categoria.uid = categoriaDb.uid;
          let portate = new Array<PortateFis>();
          Object.keys(categoriaDb.portate).forEach(el => {
            
            let portataDb = categoriaDb.portate[el];
            console.log("EL",portataDb);
            let portata = {} as PortateFis;
            portata.categoria = portataDb.categoria;
            portata.descrizione_it = portataDb.descrizione_it;
            portata.descrizione_en = portataDb.descrizione_en;
            portata.titolo_it = portataDb.titolo_it;
            portata.titolo_en = portataDb.titolo_en;
            portata.custom = portataDb.custom;
            portata.foto = portataDb.foto;
            portata.fuoriMenu = portataDb.fuoriMenu;
            portata.prezzo = portataDb.prezzo;
            portata.prezzobanco = portataDb.prezzobanco;
            portata.ingredienti = portataDb.ingredienti;
            portata.supplementi = portataDb.supplementi;
            portata.step = portataDb.step;
            if (portata.step) {
              portata.step.forEach(step => {
                step.supplementi.forEach(sup => {
                  sup.descrizione_it =
                    '(' + step.desc_step_it + ') ' + sup.descrizione_it;
                  sup.descrizione_en =
                    '(' + step.desc_step_en + ') ' + sup.descrizione_en;
                });
              });
            }
            portata.image_dettaglio = portataDb.image_dettaglio;
            portata.uid = portataDb.uid;
            if (!portata.fuoriMenu) {
              portate.push(portata);
            }
          });
          categoria.portate = portate;
          if (categoria.descrizione_it.toUpperCase() == 'BANCO') {
            this.catBanco = categoria;
            this.catSelMenu = categoria.uid;
            this.uidCatBanco = categoria.uid;
          }
          this.categorieMenu.push(categoria);
          
        });
        console.log("CAT_MENU", this.categorieMenu);
      });
  }

  filterBy(cat: string) {
    return this.categorieMenu.filter(categoria => cat === categoria.uid);
  }
  setCatSelMenu(uid: string) {
    this.catSelMenu = uid;
  }
  editEntry(content, index) {
    this.currentModIndex = this.currentModIndex
      ? this.currentModIndex
      : String(this.orderList.length - (index + 1));
    if (this.orderList[this.orderList.length - (index + 1)].pietanza.note) {
      this.currentModNota = this.orderList[
        this.orderList.length - (index + 1)
      ].pietanza.note;
    }
    this.modalService
      .open(content, { windowClass: 'contentUno' })
      .result.then(result => {
        this.saveModPopUp(
          this.currentModIndex,
          this.currentModIng,
          this.currentModSup,
          this.currentModStepSup,
          this.currentModNota
        );
        this.markUndefCurrentMod();
        this.totaleScontrino = this.prezzoTotale;
        this.calcolaImpSconto(this.percSconto);
      })
      .catch(err => {
        console.log('ERR', err);
        this.markUndefCurrentMod();
      });
  }
  markUndefCurrentMod() {
    this.currentModIndex = undefined;
    this.currentModIng = undefined;
    this.currentModSup = undefined;
    this.currentModNota = undefined;
    this.currentModStepSup = undefined;
  }

  addScontrinoFis(portata: PortateFis) {
    if (!this.orderList) {
      this.orderList = new Array<ScontrinoFis>();
    }
    let newEntry = {} as ScontrinoFis;
    newEntry.uidPortata = portata.uid;
    newEntry.quantita = 1;
    newEntry.prezzo = portata.prezzobanco
      ? portata.prezzobanco
      : portata.prezzo;
    newEntry.pietanza = JSON.parse(JSON.stringify(portata));
    if (newEntry.pietanza.categoria != this.uidCatBanco) {
      this.enableInviaComanda = true;
    }
    this.orderList.push(newEntry);
    this.prezzoTotale += newEntry.prezzo;
    this.totaleScontrino = this.prezzoTotale;
    this.calcolaImpSconto(this.percSconto);
  }

  rmIng(iEntry: string, iIng: number, event) {
    this.currentModIndex = String(this.orderList.length - (+iEntry + 1));
    if (!this.currentModIng) {
      this.currentModIng = new Array<IngMod>();
    }
    let index = this.currentModIng.findIndex(item => item.index == iIng);
    if (index != -1) {
      this.currentModIng[index].checked = event.target.checked;
    } else {
      let ingMod = {} as IngMod;
      ingMod.index = iIng;
      ingMod.checked = event.target.checked;
      this.currentModIng.push(ingMod);
    }
  }

  calcolaResto(calcolaResto) {
    console.log("APRO");
    this.modalService.open(calcolaResto).result.then(()=>this.contantivers = undefined ).catch(()=> this.contantivers = undefined);
  }

  addSup(iEntry: string, iSup: number, event) {
    this.currentModIndex = String(this.orderList.length - (+iEntry + 1));
    if (!this.currentModSup) {
      this.currentModSup = new Array<SupMod>();
    }
    let index = this.currentModSup.findIndex(item => item.index == iSup);
    if (index != -1) {
      this.currentModSup[index].checked = event.target.checked;
    } else {
      let supMod = {} as SupMod;
      supMod.index = iSup;
      supMod.checked = event.target.checked;
      this.currentModSup.push(supMod);
    }
  }

  addSupStep(entry_index: string, i: number, suppStep: number, event) {
    this.currentModIndex = String(this.orderList.length - (+entry_index + 1));
    if (!this.currentModStepSup) {
      this.currentModStepSup = new Array<StepMod>();
    }
    let index = this.currentModStepSup.findIndex(
      item => item.index == i && item.sup_ind == suppStep
    );
    if (index != -1) {
      this.currentModStepSup[index].checked = event.target.checked;
    } else {
      let stepSupMod = {} as StepMod;
      stepSupMod.index = i;
      stepSupMod.sup_ind = suppStep;
      stepSupMod.checked = event.target.checked;
      this.currentModStepSup.push(stepSupMod);
    }
  }

  saveModPopUp(
    iEntry,
    listIng: Array<IngMod>,
    listSup: Array<SupMod>,
    listStep: Array<StepMod>,
    note: string
  ) {
    if (note) {
      this.orderList[iEntry].pietanza.note = note;
    }
    if (listIng) {
      listIng.forEach(ing => {
        this.orderList[iEntry].pietanza.ingredienti[ing.index].rm = ing.checked;
        if (!ing.checked) {
          if (!this.orderList[iEntry].pietanza.ingredientiEliminati) {
            this.orderList[iEntry].pietanza.ingredientiEliminati = new Array<
              IngredientiFis
            >();
          }
          this.orderList[iEntry].pietanza.ingredientiEliminati.push(
            this.orderList[iEntry].pietanza.ingredienti[ing.index]
          );
        } else {
          let index = this.orderList[
            iEntry
          ].pietanza.ingredientiEliminati.findIndex(
            item =>
              item.uid ==
              this.orderList[iEntry].pietanza.ingredienti[ing.index].uid
          );
          this.orderList[iEntry].pietanza.ingredientiEliminati.splice(index, 1);
        }
      });
    }
    if (listSup) {
      listSup.forEach(sup => {
        this.orderList[iEntry].pietanza.supplementi[sup.index].add =
          sup.checked;
        if (sup.checked) {
          if (!this.orderList[iEntry].pietanza.supplementiAggiunti) {
            this.orderList[iEntry].pietanza.supplementiAggiunti = new Array<
              SupplementiFis
            >();
          }

          this.orderList[iEntry].pietanza.supplementiAggiunti.push(
            this.orderList[iEntry].pietanza.supplementi[sup.index]
          );
          this.orderList[iEntry].prezzo += this.orderList[
            iEntry
          ].pietanza.supplementi[sup.index].prezzo;
          this.prezzoTotale += this.orderList[iEntry].pietanza.supplementi[
            sup.index
          ].prezzo;
        } else {
          let index = this.orderList[
            iEntry
          ].pietanza.supplementiAggiunti.findIndex(
            item =>
              item.uid ==
              this.orderList[iEntry].pietanza.supplementi[sup.index].uid
          );
          this.orderList[iEntry].pietanza.supplementiAggiunti.splice(index, 1);
          this.orderList[iEntry].prezzo -= this.orderList[
            iEntry
          ].pietanza.supplementi[sup.index].prezzo;
          this.prezzoTotale -= this.orderList[iEntry].pietanza.supplementi[
            sup.index
          ].prezzo;
        }
      });
    }
    if (listStep) {
      console.log('LISTSTEP', listStep);
      listStep.forEach(sup => {
        this.orderList[iEntry].pietanza.step[sup.index].supplementi[
          sup.sup_ind
        ].add = sup.checked;
        if (sup.checked) {
          if (!this.orderList[iEntry].pietanza.supplementiAggiunti) {
            this.orderList[iEntry].pietanza.supplementiAggiunti = new Array<
              SupplementiFis
            >();
          }
          this.orderList[iEntry].pietanza.supplementiAggiunti.push(
            this.orderList[iEntry].pietanza.step[sup.index].supplementi[
              sup.sup_ind
            ]
          );
          this.orderList[iEntry].prezzo += this.orderList[iEntry].pietanza.step[
            sup.index
          ].supplementi[sup.sup_ind].prezzo;
          this.prezzoTotale += this.orderList[iEntry].pietanza.step[
            sup.index
          ].supplementi[sup.sup_ind].prezzo;
        } else {
          let index = this.orderList[
            iEntry
          ].pietanza.supplementiAggiunti.findIndex(
            item =>
              item.uid ==
              this.orderList[iEntry].pietanza.step[sup.index].supplementi[
                sup.sup_ind
              ].uid
          );
          this.orderList[iEntry].pietanza.supplementiAggiunti.splice(index, 1);
          this.orderList[iEntry].prezzo -= this.orderList[iEntry].pietanza.step[
            sup.index
          ].supplementi[sup.sup_ind].prezzo;
          this.prezzoTotale -= this.orderList[iEntry].pietanza.step[
            sup.index
          ].supplementi[sup.sup_ind].prezzo;
        }
      });
    }
    if (note) {
      this.orderList[iEntry].pietanza.note = note;
    }
  }

  deleteEntry(index: number) {
    let i = this.orderList.length - (index + 1);
    this.prezzoTotale -= this.orderList[i].prezzo;
    this.totaleScontrino = this.prezzoTotale;
    this.orderList.splice(i, 1);
    this.checkInviaComanda();
    this.calcolaImpSconto(this.percSconto);
  }
  incQ(index: number) {
    let i = this.orderList.length - (index + 1);
    this.orderList[i].quantita += 1;
    this.prezzoTotale += this.orderList[i].prezzo;
    this.totaleScontrino = this.prezzoTotale;
    this.calcolaImpSconto(this.percSconto);
  }
  decQ(index: number) {
    let i = this.orderList.length - (index + 1);
    if (this.orderList[i].quantita > 1) {
      this.orderList[i].quantita -= 1;
      this.prezzoTotale -= this.orderList[i].prezzo;
      this.totaleScontrino = this.prezzoTotale;
      this.calcolaImpSconto(this.percSconto);
    }
  }
  getMaxOrder() {
    this.firebaseService
      .getMaxOrder(this.afAuth.auth.currentUser.uid)
      .then(snap => {
        this.maxOrder = snap.val() != null ? snap.val() + 1 : 1;
      });
  }
  // VARIE
  digit(val: string) {
    let i = this.varie.indexOf('.');
    let dim = this.varie.length;
    if (!(val == '.' && i != -1) && dim < 7 && (i == -1 || i > dim - 3)) {
      this.varie = this.varie.concat(val);
    }
  }
  clear(n) {
    this.varie = n ? this.varie.substr(0, this.varie.length - n) : '';
  }
  varieSubmit() {
    let varie = {} as PortateFis;
    varie.uid = 'var';
    varie.descrizione_en = 'Various';
    varie.descrizione_it = 'Varie';
    varie.prezzo = +this.varie;
    varie.prezzobanco = varie.prezzo;
    if (+this.varie > 0) {
      this.addScontrinoFis(varie);
    }
    this.calcolatrice = false;
    this.clear(false);
  }
  calcolaImpSconto(scontoPerc) {
    if (scontoPerc) {
      this.percSconto = scontoPerc;
    } else {
      this.percSconto = 0;
    }
    this.sconto = +(this.prezzoTotale * (this.percSconto / 100)).toFixed(2);
    this.totaleScontrino = this.prezzoTotale - this.sconto;
    this.impSconto = this.sconto;
  }
  calcolaPercSconto() {
    this.percSconto = 100 - (this.totaleScontrino * 100) / this.prezzoTotale;
    this.percSconto = +this.percSconto.toFixed(2);
    this.sconto = +(this.prezzoTotale * (this.percSconto / 100)).toFixed(2);
    this.totaleScontrino = this.prezzoTotale - this.sconto;
    this.impSconto = this.sconto;
  }

  salvaOrdine(action: number) {
    if (this.orderList) {
      let ffOrdini = {} as FFOrdini;
      ffOrdini.numeroOrdine = this.maxOrder;
      ffOrdini.prezzo = this.prezzoTotale;
      ffOrdini.pagamento = this.contanti ? 'C' : 'CC';
      ffOrdini.sconto = this.sconto ? this.sconto : 0;
      ffOrdini.totaleScontrino = this.prezzoTotale - ffOrdini.sconto;
      ffOrdini.percSonto = this.percSconto;
      ffOrdini.orderEntryList = this.orderList;
      console.log('FFORDINI', ffOrdini);
      this.firebaseService
        .addFFOrdini(this.afAuth.auth.currentUser.uid, ffOrdini)
        .then(() => {
          if (action == 1) {
            this.sooneatService.contoFiscaleFF(
              this.afAuth.auth.currentUser.uid,
              this.currentLang
            );
          }
          if (action == 2) {
            this.sooneatService.contoFF(
              this.afAuth.auth.currentUser.uid,
              this.currentLang
            );
          }
          if (action == 3) {
            this.sooneatService.comandaFF(
              this.afAuth.auth.currentUser.uid,
              this.currentLang
            );
          }
        });
    }
  }
  stampaFiscale() {
    this.salvaOrdine(1);
  }
  stampaCortesia() {
    this.salvaOrdine(2);
  }

  inviaComanda() {
    console.log('************ STAMPA COMANDA BANCO ************');
    this.salvaOrdine(3);
  }

  chiudiScontrino() {
    this.firebaseService.closeFF(
      this.afAuth.auth.currentUser.uid,
      this.maxOrder
    );
    let statisticheGiornata: StatisticheRisto;
    this.firebaseService
      .getStatisticheGiornata(this.afAuth.auth.currentUser.uid)
      .then(snap => {
        if (snap.val() !== null) {
          statisticheGiornata = snap.val();
        } else {
          statisticheGiornata = new StatisticheRisto();
        }

        statisticheGiornata.cartaBanco =
          (statisticheGiornata.cartaBanco
            ? statisticheGiornata.cartaBanco
            : 0) + (!this.contanti ? this.totaleScontrino : 0);
        statisticheGiornata.contantiBanco =
          (statisticheGiornata.contantiBanco
            ? statisticheGiornata.contantiBanco
            : 0) + (this.contanti ? this.totaleScontrino : 0);
        statisticheGiornata.totaleGiorno += this.totaleScontrino;
        statisticheGiornata.prodotti = this.addStatitsticheRisto(
          statisticheGiornata.prodotti
        );
        this.firebaseService
          .updateStatisticheGiornata(
            this.afAuth.auth.currentUser.uid,
            statisticheGiornata
          )
          .then(() => {
            this.markUndefCurrentMod();
            this.orderList = undefined;
            this.chiudiscontrino = false;
            this.calcolatrice = false;
            this.sconto = 0;
            this.totaleScontrino = 0;
            this.prezzoTotale = 0;
            this.impSconto = 0;
            this.percSconto = 0;
            this.contanti = true;
            this.carta = false;
          });
      });
  }

  addStatitsticheRisto(
    statisticheProdotti: Array<StatisticheProdotti>
  ): Array<StatisticheProdotti> {
    if (!statisticheProdotti) {
      statisticheProdotti = new Array<StatisticheProdotti>();
    }
    this.orderList.forEach(entry => {
      if (entry.pietanza.uid != 'var') {
        let index = this.getIndexStatisticheProdotto(
          statisticheProdotti,
          entry.pietanza.uid
        );
        if (index === -1) {
          let nuovoProdotto = new StatisticheProdotti();
          nuovoProdotto.uidPietanza = entry.pietanza.uid;
          nuovoProdotto.quantita = entry.quantita;
          statisticheProdotti.push(nuovoProdotto);
        } else {
          statisticheProdotti[index].quantita += entry.quantita;
        }
      }
    });

    return statisticheProdotti;
  }

  private getIndexStatisticheProdotto(
    statisticheProdotti: Array<StatisticheProdotti>,
    uidPietanza: String
  ) {
    let index;
    index = statisticheProdotti.findIndex(
      item => item.uidPietanza == uidPietanza
    );
    return index;
  }

  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^[\d.]*(?:[.,]\d{1,2})?$/;
    let i = this.varie.indexOf('.');
    let dim = this.varie.length;

    if (
      !(
        reg.test(input) &&
        (!(input == '.' && i != -1) && dim < 7 && (i == -1 || i > dim - 3))
      )
    ) {
      e.preventDefault();
    }
  }
}

export interface MenuFis {
  uid: string;
  descrizione: string;
  descrizione_it: string;
  descrizione_en: string;
  posizione: number;
  portate: Array<PortateFis>;
}
export interface PortateFis {
  uid: string;
  titolo: string;
  titolo_en: string;
  titolo_it: string;
  descrizione: string;
  descrizione_en: string;
  descrizione_it: string;
  categoria: string;
  prezzo: number;
  prezzobanco: number;
  foto: string;
  image_dettaglio: string;
  ingredienti: Array<IngredientiFis>;
  supplementi: Array<SupplementiFis>;
  ingredientiEliminati: Array<IngredientiFis>;
  supplementiAggiunti: Array<SupplementiFis>;
  step: Array<StepFis>;
  fuoriMenu: boolean;
  custom: boolean;
  note: string;
}
export interface IngredientiFis {
  uid: string;
  descrizione: string;
  descrizione_it: string;
  descrizione_en: string;
  bloccato: boolean;
  rm: boolean;
}
export interface SupplementiFis {
  uid: string;
  descrizione: string;
  descrizione_it: string;
  descrizione_en: string;
  prezzo: number;
  prezzobanco: number;
  add: boolean;
}
export interface StepFis {
  desc_step_it: string;
  desc_step_en: string;
  desc_stampa_it: string;
  desc_stampa_en: string;
  num_min: number;
  num_max: number;
  supplementi: Array<SupplementiFis>;
}
export interface ScontrinoFis {
  uidPortata: string;
  quantita: number;
  prezzo: number;
  pietanza: PortateFis;
}
export interface IngMod {
  index: number;
  checked: boolean;
}
export interface SupMod {
  index: number;
  checked: boolean;
}

export interface StepMod {
  index: number;
  sup_ind: number;
  checked: boolean;
}

export interface FFOrdini {
  numeroOrdine: number;
  orderEntryList: Array<ScontrinoFis>;
  quantita: number;
  prezzo: number;
  sconto: number;
  percSonto: number;
  totaleScontrino: number;
  pagamento: string;
}
