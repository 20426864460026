import { StatisticheProdotti } from './statisticheProdotti';

export class StatisticheRisto {
  dataStat: string;
  chiamateCameriere: number = 0;
  chiamaConto: number = 0;
  tavoliAperti: number = 0;
  utentiChat: number = 0;
  totaleGiorno: number = 0;
  totaleCarta: number = 0;
  numClientiInSala: number = 0;
  clientiInSala;
  numClientiRistorante: number = 0;
  prodotti: Array<StatisticheProdotti>;
  cartaBanco: number = 0;
  contantiBanco: number = 0;
}
