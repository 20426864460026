import { PageTitleService } from '../core/page-title/page-title.service';
import { Categoria } from './categoria';
import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { AngularFireStorage } from 'angularfire2/storage';
import Utility from '../utility';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.scss']
})
export class CategorieComponent implements OnInit {
  categoria = new Categoria();
  searchItem: string;
  categoriaSelected: Categoria;
  categorie: Array<Categoria>;
  selectedFiles: FileList;
  imgsrc;
  filename: string;
  currentLang: string;
  constructor(
    private pageTitleService: PageTitleService,
    private firebaseService: FirebaseService,
    private storage: AngularFireStorage,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.pageTitleService.setTitle(this.translate.instant('CLASSIFICATION'));
    this.translate.onLangChange.subscribe(() => {
      this.pageTitleService.setTitle(this.translate.instant('CLASSIFICATION'));
      this.currentLang = this.translate.currentLang;
      this.getCategorie();
    });
    this.getCategorie();
  }

  chooseFiles(event) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles.item(0)) {
      const file = this.selectedFiles.item(0);
      this.filename = 'categorie' + new Date().getTime();
      const uploadTask = this.storage.upload(
        '/categorie/' + this.filename,
        file
      );
      this.imgsrc = uploadTask.downloadURL();
    }
  }

  getCategorie() {
    this.firebaseService.getCategoryList().subscribe(cats => {
      this.categorie = cats;
    });
  }
  updateCategory(catUpd: Categoria) {
    this.firebaseService.updateCategory(catUpd);
  }

  deleteCategory(catDel: Categoria) {
    const confirmMessage_it =
      'Sei sicuro di voler cancellare la categoria ' + catDel.descrizione;
    const confirmMessage_en =
      'Are you sure you want to delete the category' + catDel.descrizione;
    const confirmMes: string =
      this.currentLang === 'it' ? confirmMessage_it : confirmMessage_en;
    if (confirm(confirmMes)) {
      if (catDel.image) {
        const oldname = Utility.getPathNameFromUrl(catDel.image);
        this.storage
          .ref('/categorie/')
          .child(oldname)
          .delete();
      }
      this.firebaseService.deleteCategory(catDel.uid);
    }
  }

  filterBy(prop: string) {
    return this.categorie.sort((a, b) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }

  categoryTHOnSelect($event) {
    this.categoriaSelected = $event.item; // selected object is $event.item
  }

  showAll() {
    this.categoriaSelected = undefined;
    this.searchItem = undefined;
  }

  onSubmit() {
    if (this.imgsrc) {
      this.imgsrc.subscribe(path => {
        this.categoria.image = path;
        this.firebaseService.addCategory(this.categoria);
      });
      this.filename = undefined;
      this.imgsrc = undefined;
    } else this.firebaseService.addCategory(this.categoria);
  }
}
