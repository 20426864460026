import { TranslateService } from '@ngx-translate/core';
import { PageTitleService } from './../core/page-title/page-title.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ms-downloadpage',
  templateUrl: './downloadpage.component.html',
  styleUrls: ['./downloadpage.component.scss']
})
export class DownloadpageComponent implements OnInit {
  currentLang: any;
  constructor(
    private pageTitleService: PageTitleService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle(this.translate.instant('DOWN_CONN'));
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
      this.pageTitleService.setTitle(this.translate.instant('DOWN_CONN'));
    });
    this.pageTitleService.setTitle('Connector Printer');
  }
}
