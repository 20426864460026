import { SooneatPrintService } from './../services/sooneatprint.service';
import { PrinterService } from './../services/printer.service';
import {
  Chiamata,
  CategoriaComanda,
  Comanda,
  TavoloComanda
} from './../interface';
import { StatisticheRisto } from './../managerestaurant/statisticheRisto';
import { FirebaseService } from '../services/firebase.service';

import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
  ElementRef
} from '@angular/core';
import { MenuItems } from '../core/menu/menu-items/menu-items';
import { HorizontalMenuItems } from '../core/menu/menu-items/horizontal-menu-items';
import { PageTitleService } from '../core/page-title/page-title.service';

import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { MediaChange, ObservableMedia } from '@angular/flex-layout';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Member } from '../manageuser/member';
import { ConfigPrint } from '../interface';
const screenfull = require('screenfull');

@Component({
  selector: 'sooneat-layout',
  templateUrl: './main-material.html',
  styleUrls: ['./main-material.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit, OnDestroy {
  comandeList: Array<CategoriaComanda>;
  private _router: Subscription;
  header: string;
  currentLang = 'en';
  url: string;
  showSettings = false;
  dark: boolean;
  boxed: boolean;
  horizontalMenu: boolean;
  collapseSidebar: boolean;
  compactSidebar: boolean;
  customizerIn: boolean = false;
  headerGreen: boolean = false;
  headerRed: boolean = false;
  headerYellow: boolean = false;
  root = 'ltr';
  myroot = 'ltr';
  collapsedClass: any = 'side-panel-opened';
  chatpanelOpen: boolean = false;
  themeHeaderSkinColor: any = 'header-default';
  themeSidebarSkinColor: any = 'sidebar-default';
  utente: Member;
  callCameriere = new Array<Chiamata>();
  callConto = new Array<Chiamata>();

  private _mediaSubscription: Subscription;
  sidenavOpen: boolean = false;
  sidenavMode: string = 'side';
  isMobile: boolean = false;
  private _routerEventsSubscription: Subscription;

  @ViewChild('sidenav')
  sidenav;
  @ViewChild('audioConto') audioContoRef: ElementRef;
  @ViewChild('audioCameriere') audioCameriereRef: ElementRef;

  public config: PerfectScrollbarConfigInterface = {};
  intStampa: any;
  accountDisable: boolean = false;
  subCallCameriere: Subscription;
  subCallConto: Subscription;
  constructor(
    public menuItems: MenuItems,
    public horizontalMenuItems: HorizontalMenuItems,
    private pageTitleService: PageTitleService,
    public translate: TranslateService,
    private router: Router,
    private media: ObservableMedia,
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    private sooneatService: SooneatPrintService
  ) {
    const browserLang: string = translate.getBrowserLang();
    let lang = browserLang.match(/en|it|fr/) ? browserLang : 'en';
    translate.setDefaultLang('en');
    translate.use(lang);
  }

  ngOnInit() {
    this._router = this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        this.url = event.url;
      });

    if (
      this.url != '/session/login' &&
      this.url != '/session/register' &&
      this.url != '/session/forgot-password' &&
      this.url != '/session/lockscreen'
    ) {
      const elemSidebar = <HTMLElement>(
        document.querySelector('.sidebar-container ')
      );
    }
    if (this.media.isActive('xs') || this.media.isActive('sm')) {
      this.sidenavMode = 'over';
      this.sidenavOpen = false;
    }
    this._mediaSubscription = this.media
      .asObservable()
      .subscribe((change: MediaChange) => {
        let isMobile = change.mqAlias == 'xs' || change.mqAlias == 'sm';

        this.isMobile = isMobile;
        this.sidenavMode = isMobile ? 'over' : 'side';
        //this.sidenavOpen = !isMobile;
      });

    this._routerEventsSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.isMobile) {
        this.sidenav.close();
      }
    });
    this.getMember(this.afAuth.auth.currentUser.uid);
    this.pageTitleService.title.subscribe((val: string) => {
      this.header = val;
    });
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
    });
    if (!this.isMobile) {
      this.stampaComanda();
    }
  }

  ngOnDestroy() {
    this._router.unsubscribe();
    this._mediaSubscription.unsubscribe();
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }

  getMember(uid: string) {
    this.firebaseService.getMember(uid).subscribe(member => {
      this.utente = member;
      if (this.utente.role == 'ristoratore') {
        this.firebaseService.getRestaurantVal(uid).then(risto => {
          if (risto.val().disabilitato) {
            this.accountDisable = true;
          } else {
            this.intStampa = risto.val().intStampa;
            this.getCallCameriere();
            this.getCallConto();
          }
        });
      }
    });
  }

  isFullscreen: boolean = false;

  menuMouseOver(): void {
    if (
      window.matchMedia(`(min-width: 960px)`).matches &&
      this.collapseSidebar
    ) {
      this.sidenav.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (
      window.matchMedia(`(min-width: 960px)`).matches &&
      this.collapseSidebar
    ) {
      this.sidenav.mode = 'side';
    }
  }

  toggleFullscreen() {
    if (screenfull.enabled) {
      screenfull.toggle();
      this.isFullscreen = !this.isFullscreen;
    }
  }

  customizerFunction() {
    this.customizerIn = !this.customizerIn;
  }
  headerSkinColorFunction(color) {
    this.themeHeaderSkinColor = color;
  }
  sidebarSkinColorFunction(color) {
    this.themeSidebarSkinColor = color;
  }
  menuToggleFunc() {
    this.sidenav.toggle();

    if (this.collapsedClass == 'side-panel-opened') {
      this.collapsedClass = 'side-panel-closed';
    } else {
      this.collapsedClass = 'side-panel-opened';
    }
  }
  menuToggleFuncGoLink(url) {
    console.log('URL', url);
    this.router.navigateByUrl('/' + url).then(() => this.menuToggleFunc());
  }

  onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

  showItem(role: string[]): boolean {
    if (role) return role.indexOf(this.utente.role) > -1;
    else return true;
  }
  logout() {
    this.afAuth.auth.signOut();
    this.router.navigateByUrl('/login');
  }

  // CHIAMATA CAMERIERE
  getCallCameriere() {
    this.subCallCameriere = this.firebaseService
      .cameriere(this.afAuth.auth.currentUser.uid)
      .subscribe(call => {
        this.callCameriere = new Array<Chiamata>();
        call.forEach(element => {
          this.firebaseService.getUtente(element.utente).then(snap => {
            element.utente = snap.val().nickname;
            this.callCameriere.push(element);
          });
        });
        if (this.audioCameriereRef && call && call.length > 0) {
          this.onAudioCameriere();
        }
      });
  }
  evadiChiamata(call: Chiamata) {
    let statisticheGiornata: StatisticheRisto;
    this.firebaseService
      .getStatisticheGiornata(this.afAuth.auth.currentUser.uid)
      .then(snap => {
        if (snap.val() !== null) {
          statisticheGiornata = snap.val();
        } else {
          statisticheGiornata = new StatisticheRisto();
        }
        statisticheGiornata.chiamateCameriere = statisticheGiornata.chiamateCameriere
          ? statisticheGiornata.chiamateCameriere + 1
          : 1;
        this.firebaseService.addStatisticheGiornata(
          this.afAuth.auth.currentUser.uid,
          statisticheGiornata,
          null
        );
        this.firebaseService.evadiChiamataCameriere(
          this.afAuth.auth.currentUser.uid,
          call.uid
        );
      });
  }
  // CHIAMATA CONTO
  getCallConto() {
    this.subCallConto = this.firebaseService
      .conto(this.afAuth.auth.currentUser.uid)
      .subscribe(call => {
        this.callConto = new Array<Chiamata>();
        call.forEach(element => {
          this.firebaseService.getUtente(element.utente).then(snap => {
            element.utente = snap.val().nickname;
            this.callConto.push(element);
          });
        });
        if (this.audioContoRef && call && call.length > 0) {
          this.onAudioConto();
        }
      });
  }
  evadiConto(call: Chiamata, noredirect: boolean) {
    let statisticheGiornata: StatisticheRisto;
    this.firebaseService
      .getStatisticheGiornata(this.afAuth.auth.currentUser.uid)
      .then(snap => {
        if (snap.val() !== null) {
          statisticheGiornata = snap.val();
        } else {
          statisticheGiornata = new StatisticheRisto();
        }
        statisticheGiornata.chiamaConto = statisticheGiornata.chiamaConto
          ? statisticheGiornata.chiamaConto + 1
          : 1;
        this.firebaseService.addStatisticheGiornata(
          this.afAuth.auth.currentUser.uid,
          statisticheGiornata,
          null
        );
        this.firebaseService.evadiChiamataConto(
          this.afAuth.auth.currentUser.uid,
          call.uid
        );
      });
    if (!noredirect) {
      this.router.navigate([
        '/managetable/managetabledetail/' +
          this.afAuth.auth.currentUser.uid +
          '/' +
          call.tavolo
      ]);
    }
  }
  evadiTutti(callList: Chiamata[], tipo: string) {
    let statisticheGiornata: StatisticheRisto;
    this.subCallCameriere.unsubscribe();
    this.subCallCameriere.remove;
    this.subCallConto.unsubscribe();
    this.subCallConto.remove;
    this.firebaseService
      .getStatisticheGiornata(this.afAuth.auth.currentUser.uid)
      .then(snap => {
        if (snap.val() !== null) {
          statisticheGiornata = snap.val();
        } else {
          statisticheGiornata = new StatisticheRisto();
        }
        if (tipo === 'conto') {
          statisticheGiornata.chiamaConto = statisticheGiornata.chiamaConto
            ? statisticheGiornata.chiamaConto + callList.length
            : 1;
          this.firebaseService.addStatisticheGiornata(
            this.afAuth.auth.currentUser.uid,
            statisticheGiornata,
            null
          );
          this.firebaseService
            .evadiAllChiamataConto(this.afAuth.auth.currentUser.uid)
            .then(() => this.getCallConto());
        } else {
          statisticheGiornata.chiamateCameriere = statisticheGiornata.chiamateCameriere
            ? statisticheGiornata.chiamateCameriere + callList.length
            : callList.length;
          this.firebaseService.addStatisticheGiornata(
            this.afAuth.auth.currentUser.uid,
            statisticheGiornata,
            null
          );
          this.firebaseService
            .evadiAllChiamataCameriere(this.afAuth.auth.currentUser.uid)
            .then(() => this.getCallCameriere());
        }
      });
  }

  stampaComanda() {
    console.log('************ STAMPA COMANDA ************');
    this.sooneatService.comanda(
      this.afAuth.auth.currentUser.uid,
      this.currentLang
    );
    setTimeout(
      () => this.stampaComanda(),
      this.intStampa ? this.intStampa : 60000
    );
  }

  private addCategoriaComanda(
    uidCategoria: string,
    nomeCategoria: string,
    uidTavolo: string,
    nomeTavolo: string,
    comanda: Comanda
  ): any {
    throw new Error('Method not implemented.');
  }

  onAudioConto() {
    this.audioContoRef.nativeElement.play();
  }

  onAudioCameriere() {
    console.log('AUDIO CAMERIERE');
    this.audioCameriereRef.nativeElement.play();
  }
}
