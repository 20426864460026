import { CustomValidators } from 'ng2-validation';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'app-email',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password-component.scss']
})
export class ResetPasswordComponent implements OnInit {
  authState: any = null;
  error: any;
  resetPassword = false;
  public form: FormGroup;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.afAuth.authState.subscribe(auth => {
      this.authState = auth;
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email])
      ]
    });
  }

  isValidMailFormat(email: string) {
    const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (email.length === 0 && !EMAIL_REGEXP.test(email)) {
      return false;
    }
    return true;
  }

  resetEmailPassword() {
    this.error = '';
    if (this.form.valid) {
      console.log(this.form.value.email);
      return this.afAuth.auth
        .sendPasswordResetEmail(this.form.value.email)
        .then(() => {
          this.resetPassword = true;
          if (confirm('Email sent !!!')) {
            this.router.navigate(['/']);
          }
        })
        .catch(error => {
          this.resetPassword = false;
          this.error = error;
        });
    }
  }
}
