import * as shajs from 'sha.js';
import * as qz from 'qz-tray';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
declare var $: any;
var r = require('jsrsasign');
const privateKey =
  '-----BEGIN PRIVATE KEY----- MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC+WjCzbGZBG44y34HH0LRJjbi5vRfdJEYNWKHHDizmms2aCwk8Z+SJJOfcox1dk7oI7CHuFoFl7XgJsUsz2/MhpKEtZ8W6enPIVDsQ1tpWgrN4GydgLcxYthuX2vdWdeI3Tjl6uEE9T2m+dTDgx/d4FF/Sdj1fXuiPICYiffoPh1EGfJs+WHo+10DUpr/DuUGzZGmLFm25TyA6b/9xICtHlw20dlfoliZzBksnCGaCNk4kNB/a+oDFE327eyXXn9zOUwDLkoMj6bxbpkwZ/hoz6zihFQEyODi1ASuFr12aeBbfEKUs8h7LT3KGJgasbp98KKQ0UTdS2tCrsDU8/cIHAgMBAAECggEAWXqeAAnJu3tPidyE7j/OL/E9z5ny2NJPV8C9M4wXUUOduHbNnaacyFcf4xiOGknyHnWZsYjExPwu/CdqWXNgRAVWeriYOwOK37WwmkbVFeuUn7Xr+Ystztmz+NtGf08kyqir3mM0z7Q/2fbOxitZygmRzaYlTWqY2YbcRkR/wsIgsPofyUf+VG2T7Gdfp8p9k6QIOAUSjjKIkwMQG7tmyL8gPoFhMwe+Jra/+HkMc41FxgSpoTnRfwHKgp5C6SZ4BnuI+stchxcebmDkeFk1nn2WWYEwE958Ik8a/MoCZRLE2pKoPHSeZIHuJqELqzUADa2KavTy4psSHn3hwrsQSQKBgQD9aYMjGTwAzT3L9KxjLoI0sX8BJq+MpOH85BhZegzgiVShQtKQnM2a6som4tWp2gPucDRcGfmigVvnTafY+RLFgaPhB0ycYDv9hi+D3wMQ1VAdTGhkZrgroVZ9O+3ySJ0bstyK9mPiLjbgpNyP7f7Lj0G9YHAam6rgg9d/6sXdMwKBgQDAS9KQlsqGwKa9sZ+Z/a0jaNdXKEDMhwSJQsA547v1wfVEZuxZthdUKQFk3WwkRFT2/0phm5jl+BqYNlN9vGDKPkDsitdYXSfDIDl9hfZsyD0EJ2olV1Dze/6IVG8EiP90FfIVJsjp3RZJmMQFyWRTLRC3PdnmjDWXT5efs8j/3QKBgGjlXBsTPvs1+faK+TC098DIGEJpuREGz2TXJh0joGDxd86o0nB598HAnp0qGDeOv0HbwFC/j83srhY41tnBgDuxRhzH4LZ6P1raSKDVl3dIVTlLf4rP2jx1NE/ylrRLynzEtPXwt7fQWXbzxzjs0yN/0T6KxNy+wyTcbj/M9spxAoGAGs/3sjV0U8m09io4KM6YP6G0tPBl6J7C4SiviOLaKHA89KatOe0L365ABYJiO8RTXIVIJ8f8T8SCYMriZVvI2ypG/zR0xltm2ew6V+g/7fcVmuisAjzkiq61VOkA+c7oX5VXxd88kG9FVkm4u13TYU2dUQ+MvHq8VjbA24HD+vkCgYEAztFLkaKKozlN1iqpna/tmV6zzEJlPgqo22p4P4xT0uNp3I7GiMbytQ2/y7msFMrxSiNUBYPBJzUEfTa9oe/jMIqxQ3nMfPcYsScm+Zsg26b8A1CzZsXAnqfnGdCNBlCZwBSPBSzQcNol8lbH65m+aqS7hcdvYtJNO1fYolzTXTo=-----END PRIVATE KEY-----';

@Injectable()
export class PrinterService {
  constructor() {
    qz.api.setSha256Type(function(data) {
      return shajs('sha256')
        .update(data)
        .digest('hex');
    });

    qz.api.setPromiseType(function promise(resolver) {
      return new Promise(resolver);
    });
    qz.security.setCertificatePromise(function(resolve, reject) {
      $.ajax({
        url: 'assets/qzcertificate/digital-certificate.txt',
        cache: false,
        dataType: 'text'
      }).then(resolve, reject);
    });
    qz.security.setSignaturePromise(function(toSign) {
      return function(resolve, reject) {
        try {
          //var pem = ru.readFile('assets/qzcertificate/private-key.pem');
          var pk = r.KEYUTIL.getKey(privateKey);
          var sig = new r.KJUR.crypto.Signature({ alg: 'SHA1withRSA' });
          sig.init(pk);
          sig.updateString(toSign);
          var hex = sig.sign();
          resolve(r.stob64(r.hextorstr(hex)));
        } catch (err) {
          console.error(err);
          reject(err);
        }
      };
    });
  }

  errorHandler(error: any): Observable<any> {
    return Observable.throw(error);
  }

  // Get list of printers connected
  getPrinters(): Observable<string[]> {
    let promise = this.isActive()
      ? qz.printers.find()
      : qz.websocket.connect().then(() => qz.printers.find());
    return Observable.fromPromise(promise)
      .map((printers: string[]) => printers)
      .catch(this.errorHandler);
  }

  // Get the SPECIFIC connected printer
  getPrinter(printerName: string): Observable<string> {
    let promise = this.isActive()
      ? qz.printers.find(printerName)
      : qz.websocket.connect().then(() => qz.printers.find(printerName));
    return Observable.fromPromise(promise)
      .map((printer: string) => printer)
      .catch(this.errorHandler);
  }

  // Print data to chosen printer
  printData(printer: string, data: any): Observable<any> {
    // Create a default config for the found printer
    const config = qz.configs.create(printer);
    return Observable.fromPromise(qz.print(config, data))
      .map((anything: any) => anything)
      .catch(this.errorHandler);
  }

  // Disconnect QZ Tray from the browser
  removePrinter(): void {
    qz.websocket.disconnect();
  }

  isActive(): boolean {
    return qz.websocket.isActive();
  }

  connect() {
    return qz.websocket.connect();
  }
}
