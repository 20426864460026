import { PrimoPiano } from './managerestaurant/primopiano';
import { Portate } from './managemenu/portate/portate';
import { Ingredienti } from 'app/managemenu/ingredienti/ingredienti';
import { Supplementi } from './managemenu/supplementi/supplementi';
import {
  Element,
  ElementMens,
  TranslateEn,
  TranslatePortateEn,
  TranslateDesTitEn
} from './interface';
import { FirebaseService } from './services/firebase.service';
import { GoogleTranslateService } from './services/google-translate.service';
import { CategoriaMenu } from './managemenu/categoriemenu/categoria-menu';
import { Promozione } from './promozioni/promozione';
export default class Utility {
  constructor() {}

  static getPathNameFromUrl(imagePath) {
    let name = imagePath.substr(
      imagePath.lastIndexOf('%2F') + 3,
      imagePath.indexOf('?') - (imagePath.lastIndexOf('%2F') + 3)
    );
    return (name = name.replace('%20', ' '));
  }

  static isMobile(): boolean {
    if (sessionStorage.desktop)
      // desktop storage
      return false;
    else if (localStorage.mobile)
      // mobile storage
      return true;
    else {
      const mobile = [
        'iphone',
        'ipad',
        'android',
        'blackberry',
        'nokia',
        'opera mini',
        'windows mobile',
        'windows phone',
        'iemobile',
        'tablet',
        'mobi'
      ];
      let ua = navigator.userAgent.toLowerCase();
      for (let i in mobile) if (ua.indexOf(mobile[i]) > -1) return true;
      return false;
    }
  }

  static tableIDGenerator(length: number): string {
    const randomNum = (
      Math.pow(10, length)
        .toString()
        .slice(length - 1) +
      Math.floor(Math.random() * Math.pow(10, length) + 1).toString()
    ).slice(-length);
    return randomNum;
  }

  static getAggregationMonth(cassa: Element[]): ElementMens[] {
    let cassaMens = new Array<ElementMens>();
    cassa.forEach(entry => {
      cassaMens = Utility.addEntryMonth(entry, cassaMens);
    });

    return cassaMens;
  }
  static addEntryMonth(
    entry: Element,
    cassaMens: ElementMens[]
  ): ElementMens[] {
    let itemIndex = cassaMens.findIndex(
      item => item.dataMens === entry.data.substring(3)
    );
    if (itemIndex != -1) {
      cassaMens[itemIndex].cartaMens += +entry.carta;
      cassaMens[itemIndex].contantiMens += +entry.contanti;
      cassaMens[itemIndex].totaleMens += +entry.totale;
      cassaMens[itemIndex].copertiMens += +entry.coperti;
      cassaMens[itemIndex].bancoMens += +entry.banco;
      cassaMens[itemIndex].ccBancoMens += +entry.ccBanco;
    } else {
      let entryMens = {} as ElementMens;
      entryMens.dataMens = entry.data.substring(3);
      entryMens.cartaMens = +entry.carta;
      entryMens.contantiMens = +entry.contanti;
      entryMens.totaleMens = +entry.totale;
      entryMens.copertiMens = +entry.coperti;
      entryMens.bancoMens = +entry.banco;
      entryMens.ccBancoMens = +entry.ccBanco;
      cassaMens.push(entryMens);
    }
    return cassaMens;
  }

  static encodeGetDay(indexDay) {
    let mapDay = [
      'domenica',
      'lunedi',
      'martedi',
      'mercoledi',
      'giovedi',
      'venerdi',
      'sabato'
    ];
    return mapDay[indexDay];
  }

  static encodingToHtml(str) {
    let mapChar = {
      '&#176;': '&deg;',
      '&#192;': '&Agrave;',
      '&#193;': '&Aacute;',
      '&#194;': '&Acirc;',
      '&#195;': '&Atilde;',
      '&#196;': '&Auml;',
      '&#197;': '&Aring;',
      '&#198;': '&AElig;',
      '&#199;': '&Ccedil;',
      '&#200;': '&Egrave;',
      '&#201;': '&Eacute;',
      '&#202;': '&Ecirc;',
      '&#203;': '&Euml;',
      '&#204;': '&Igrave;',
      '&#205;': '&Iacute;',
      '&#206;': '&Icirc;',
      '&#207;': '&Iuml;',
      '&#208;': '&ETH;',
      '&#209;': '&Ntilde;',
      '&#210;': '&Ograve;',
      '&#211;': '&Oacute;',
      '&#212;': '&Ocirc;',
      '&#213;': '&Otilde;',
      '&#214;': '&Ouml;',
      '&#216;': '&Oslash;',
      '&#217;': '&Ugrave;',
      '&#218;': '&Uacute;',
      '&#219;': '&Ucirc;',
      '&#220;': '&Uuml;',
      '&#221;': '&Yacute;',
      '&#222;': '&THORN;',
      '&#223;': '&szlig;',
      '&#224;': '&agrave;',
      '&#225;': '&aacute;',
      '&#226;': '&acirc;',
      '&#227;': '&atilde;',
      '&#228;': '&auml;',
      '&#229;': '&aring;',
      '&#230;': '&aelig;',
      '&#231;': '&ccedil;',
      '&#232;': '&egrave;',
      '&#233;': '&eacute;',
      '&#234;': '&ecirc;',
      '&#235;': '&euml;',
      '&#236;': '&igrave;',
      '&#237;': '&iacute;',
      '&#238;': '&icirc;',
      '&#239;': '&iuml;',
      '&#240;': '&eth;',
      '&#241;': '&ntilde;',
      '&#242;': '&ograve;',
      '&#243;': '&oacute;',
      '&#244;': '&ocirc;',
      '&#245;': '&otilde;',
      '&#246;': '&ouml;',
      '&#248;': '&oslash;',
      '&#249;': '&ugrave;',
      '&#250;': '&uacute;',
      '&#251;': '&ucirc;',
      '&#252;': '&uuml;',
      '&#253;': '&yacute;',
      '&#254;': '&thorn;',
      '&#255;': '&yuml;'
    };

    let i = str.length,
      aRet = [];

    while (i--) {
      let iC = str[i].charCodeAt();
      if (iC == 176 || (iC > 191 && iC < 255)) {
        aRet[i] = mapChar['&#' + iC + ';'];
      } else {
        aRet[i] = str[i];
      }
    }
    return aRet.join('');
  }

  static unescapeHtml(str) {
    const map = {
      amp: '&',
      lt: '<',
      le: '≤',
      gt: '>',
      ge: '≥',
      quot: '"',
      '#39': "'"
    };
    return str.replace(/&([^;]+);/g, (m, c) => map[c] || '');
  }

  static translateAll(
    uidRestaurant: string,
    firebaseService: FirebaseService,
    googleTranslate: GoogleTranslateService
  ) {
    let promise = new Array<any>();
    promise.push(this.translatePromozioni(firebaseService, googleTranslate));
    promise.push(this.translatePrimoPiano(firebaseService, googleTranslate));
    promise.push(
      this.translateCategorieMenu(
        uidRestaurant,
        firebaseService,
        googleTranslate
      )
    );

    promise.push(
      this.translateIng(uidRestaurant, firebaseService, googleTranslate)
    );

    promise.push(
      this.translateSupp(uidRestaurant, firebaseService, googleTranslate)
    );
    Promise.all(promise).then(() => {
      this.translateMenu(uidRestaurant, firebaseService, googleTranslate);
    });
  }

  public static translatePromozioni(
    firebaseService: FirebaseService,
    googleTranslate: GoogleTranslateService
  ) {
    let UIDTranslate = new Array<TranslateDesTitEn>();
    let textTranslate: string;
    firebaseService.getObjPromozioniList().then(promo => {
      const promotion = promo.val();
      Object.keys(promotion).forEach(c => {
        let translateEn = {} as TranslateDesTitEn;
        if (!promotion[c].descrizione_en || !promotion[c].descrizione_it) {
          translateEn.uid = c;
          translateEn.descrizione_it = promotion[c].descrizione;
          translateEn.titolo_it = promotion[c].titolo;
          UIDTranslate.push(translateEn);
          textTranslate = textTranslate
            ? textTranslate +
              '||' +
              promotion[c].descrizione +
              '|' +
              promotion[c].titolo
            : promotion[c].descrizione + '|' + promotion[c].titolo;
        }
      });
      googleTranslate.translate('en', textTranslate).subscribe(text => {
        let resTextTranslate = text.data.translations[0].translatedText.split(
          '||'
        );
        let i = 0;
        UIDTranslate.forEach(uid => {
          let tradPromo = resTextTranslate[i++].split('|');
          let updPromo = new Promozione();
          updPromo.uid = uid.uid;
          updPromo.descrizione_it = uid.descrizione_it;
          updPromo.descrizione_en = tradPromo[0].trim();
          updPromo.titolo_it = uid.titolo_it;
          updPromo.titolo_en = tradPromo[1].trim();
          firebaseService.updatePromo(updPromo);
        });
      });
    });
  }

  public static translatePrimoPiano(
    firebaseService: FirebaseService,
    googleTranslate: GoogleTranslateService
  ) {
    let UIDTranslate = new Array<TranslateDesTitEn>();
    let textTranslate: string;
    firebaseService.getObjRistoList().then(risto => {
      const ristoList = risto.val();
      Object.keys(ristoList).forEach(ruid => {
        const primoPianoList = ristoList[ruid].primoPiano;
        Object.keys(primoPianoList).forEach(pp => {
          let translateEn = {} as TranslateDesTitEn;
          if (
            !primoPianoList[pp].descrizione_en ||
            !primoPianoList[pp].descrizione_it
          ) {
            translateEn.uid = pp;
            translateEn.descrizione_it = primoPianoList[pp].descrizione;
            translateEn.titolo_it = primoPianoList[pp].titolo;
            UIDTranslate.push(translateEn);
            textTranslate = textTranslate
              ? textTranslate +
                '||' +
                primoPianoList[pp].descrizione +
                '|' +
                primoPianoList[pp].titolo
              : primoPianoList[pp].descrizione +
                '|' +
                primoPianoList[pp].titolo;
          }
        });
        googleTranslate.translate('en', textTranslate).subscribe(text => {
          let resTextTranslate = text.data.translations[0].translatedText.split(
            '||'
          );
          let i = 0;
          UIDTranslate.forEach(uid => {
            let tradPromo = resTextTranslate[i++].split('|');
            let updPP = new PrimoPiano();
            updPP.uid = uid.uid;
            updPP.descrizione_it = uid.descrizione_it;
            updPP.descrizione_en = tradPromo[0].trim();
            updPP.titolo_it = uid.titolo_it;
            updPP.titolo_en = tradPromo[1].trim();
            firebaseService.updatePrimoPiano(ruid, updPP);
          });
        });
      });
    });
  }

  private static translateCategorieMenu(
    uidRestaurant: string,
    firebaseService: FirebaseService,
    googleTranslate: GoogleTranslateService
  ) {
    let UIDTranslate = new Array<TranslateEn>();
    let textTranslate: string;
    let categoriaML: CategoriaMenu;
    firebaseService.getObjCategorieMenuList(uidRestaurant).then(cat => {
      Object.keys(cat.val()).forEach(c => {
        categoriaML = cat.val()[c];
        let translateEn = {} as TranslateEn;
        if (!categoriaML.descrizione_en || !categoriaML.descrizione_it) {
          translateEn.uid = categoriaML.uid;
          translateEn.descrizione_it = categoriaML.descrizione;
          UIDTranslate.push(translateEn);
          textTranslate = textTranslate
            ? textTranslate + '|' + categoriaML.descrizione
            : categoriaML.descrizione;
        }
      });

      googleTranslate.translate('en', textTranslate).subscribe(text => {
        let resTextTranslate = text.data.translations[0].translatedText.split(
          '|'
        );
        let i = 0;
        UIDTranslate.forEach(uid => {
          let updCat = new CategoriaMenu();
          updCat.uid = uid.uid;
          updCat.descrizione_it = uid.descrizione_it;
          updCat.descrizione_en = resTextTranslate[i++].trim();
          firebaseService.updateCategorieMenu(uidRestaurant, updCat);
        });
      });
    });
  }

  private static translateIng(
    uidRestaurant: string,
    firebaseService: FirebaseService,
    googleTranslate: GoogleTranslateService
  ) {
    let UIDTranslate = new Array<TranslateEn>();
    let textTranslate: string;
    let ingredientiML: Supplementi;
    firebaseService.getObjIngredientiList(uidRestaurant).then(sup => {
      Object.keys(sup.val()).forEach(index => {
        ingredientiML = sup.val()[index];
        let translateEn = {} as TranslateEn;
        if (!ingredientiML.descrizione_en || !ingredientiML.descrizione_it) {
          ingredientiML.uid = ingredientiML.uid ? ingredientiML.uid : index;
          translateEn.uid = ingredientiML.uid;
          translateEn.descrizione_it = ingredientiML.descrizione;
          UIDTranslate.push(translateEn);
          textTranslate = textTranslate
            ? textTranslate + '|' + ingredientiML.descrizione
            : ingredientiML.descrizione;
        }
      });

      googleTranslate.translate('en', textTranslate).subscribe(text => {
        let resTextTranslate = text.data.translations[0].translatedText.split(
          '|'
        );
        let i = 0;
        UIDTranslate.forEach(uid => {
          let updIng = new Ingredienti();
          updIng.uid = uid.uid;
          updIng.descrizione_it = uid.descrizione_it;
          updIng.descrizione_en = resTextTranslate[i++]
            ? resTextTranslate[i++].trim()
            : uid.descrizione_it;
          firebaseService.updateIngredienti(uidRestaurant, updIng);
        });
      });
    });
  }

  private static translateSupp(
    uidRestaurant: string,
    firebaseService: FirebaseService,
    googleTranslate: GoogleTranslateService
  ) {
    let UIDTranslate = new Array<TranslateEn>();
    let textTranslate: string;
    let supplementiML: Supplementi;
    firebaseService.getObjSupplementiList(uidRestaurant).then(sup => {
      Object.keys(sup.val()).forEach(index => {
        supplementiML = sup.val()[index];
        let translateEn = {} as TranslateEn;
        if (!supplementiML.descrizione_en || !supplementiML.descrizione_it) {
          supplementiML.uid = supplementiML.uid ? supplementiML.uid : index;
          translateEn.uid = supplementiML.uid;
          translateEn.descrizione_it = supplementiML.descrizione;
          UIDTranslate.push(translateEn);
          textTranslate = textTranslate
            ? textTranslate + '|' + supplementiML.descrizione
            : supplementiML.descrizione;
        }
      });

      googleTranslate.translate('en', textTranslate).subscribe(text => {
        let resTextTranslate = text.data.translations[0].translatedText.split(
          '|'
        );
        let i = 0;
        UIDTranslate.forEach(uid => {
          let updSup = new Supplementi();
          updSup.uid = uid.uid;
          updSup.descrizione_it = uid.descrizione_it;
          updSup.descrizione_en = resTextTranslate[i++].trim();
          firebaseService.updateSupplementi(uidRestaurant, updSup);
        });
      });
    });
  }

  private static translateMenu(
    uidRestaurant: string,
    firebaseService: FirebaseService,
    googleTranslate: GoogleTranslateService
  ) {
    let listPortateTranslate = new Array<Portate>();
    let portateML: Portate;
    firebaseService.getObjMenu(uidRestaurant).then(cat => {
      Object.keys(cat.val()).forEach(uidCat => {
        let listPortate = cat.val()[uidCat].portate;
        if (listPortate) {
          Object.keys(listPortate).forEach(uidPortata => {
            portateML = listPortate[uidPortata];
            if (!portateML.descrizione_en || !portateML.descrizione_it) {
              listPortateTranslate.push(portateML);
            }
          });
        }
      });
      let UIDTranslate = new Array<TranslatePortateEn>();
      listPortateTranslate.forEach(portata => {
        let translateString: string;
        let translateIng: string;
        let translateSup: string;
        let translateEn = {} as TranslatePortateEn;
        translateEn.uidCat = portata.categoria;
        translateEn.uidPortata = portata.uid;
        translateEn.descrizione_it = portata.descrizione;
        translateEn.titolo_it = portata.titolo;

        if (portata.ingredienti) {
          translateEn.ingredienti = portata.ingredienti;
          translateEn.ingredienti.forEach(ing => {
            ing.descrizione_it = ing.descrizione;
            translateIng = translateIng
              ? translateIng + ' | ' + ing.descrizione
              : ing.descrizione;
          });
        } else {
          translateIng = '-';
        }
        if (portata.supplementi) {
          translateEn.supplementi = portata.supplementi;
          translateEn.supplementi.forEach(suppl => {
            suppl.descrizione_it = suppl.descrizione;
            translateSup = translateSup
              ? translateSup + ' | ' + suppl.descrizione
              : suppl.descrizione;
          });
        } else {
          translateSup = '-';
        }

        translateString =
          portata.descrizione +
          ' || ' +
          portata.titolo +
          ' || ' +
          translateIng +
          ' || ' +
          translateSup;
        translateEn.translateString = translateString;
        UIDTranslate.push(translateEn);
      });
      UIDTranslate.forEach(portata => {
        const specialChar = '&';
        let replaceSpecialChar = false;
        if (portata.translateString.indexOf('&') != -1) {
          portata.translateString = portata.translateString.replace(/&/g, '-');
          replaceSpecialChar = true;
        }
        googleTranslate
          .translate('en', portata.translateString)
          .toPromise()
          .then(text => {
            let updPortata = new Portate();
            let portataTraslString = Utility.unescapeHtml(
              text.data.translations[0].translatedText
            ).split(' || ');
            updPortata.uid = portata.uidPortata;
            updPortata.descrizione_it = portata.descrizione_it;
            updPortata.descrizione_en = replaceSpecialChar
              ? portataTraslString[0].replace('-', '&').trim()
              : portataTraslString[0].trim();
            if (portata.titolo_it) {
              updPortata.titolo_it = portata.titolo_it;
              updPortata.titolo_en = replaceSpecialChar
                ? portataTraslString[1].replace('-', '&').trim()
                : portataTraslString[1].trim();
            }
            if (portataTraslString[2] && portataTraslString[2].trim() !== '-') {
              updPortata.ingredienti = new Array<Ingredienti>();
              let ingUpd = portataTraslString[2].split(' | ');
              let ingIndex = 0;
              ingUpd.forEach(element => {
                let ingrediente: Ingredienti;
                ingrediente = portata.ingredienti[ingIndex];
                ingrediente.descrizione_it =
                  portata.ingredienti[ingIndex].descrizione_it;
                ingrediente.descrizione_en = replaceSpecialChar
                  ? element.replace('-', '&').trim()
                  : element.trim();
                updPortata.ingredienti.push(ingrediente);
                ingIndex++;
              });
            }
            if (portataTraslString[3] && portataTraslString[3].trim() !== '-') {
              updPortata.supplementi = new Array<Supplementi>();
              let supUpd = portataTraslString[3].split(' | ');
              let supIndex = 0;
              supUpd.forEach(element => {
                let supplemento: Supplementi;
                supplemento = portata.supplementi[supIndex];
                supplemento.descrizione_it =
                  portata.supplementi[supIndex].descrizione_it;
                supplemento.descrizione_en = replaceSpecialChar
                  ? element.replace('-', '&').trim()
                  : element.trim();
                updPortata.supplementi.push(supplemento);
                supIndex++;
              });
            }
            firebaseService.updatePortata(
              uidRestaurant,
              portata.uidCat,
              updPortata
            );
          });
      });
      firebaseService.updateRestaurantMenuTrad(uidRestaurant);
    });
  }
}
