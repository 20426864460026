import { ConfigPrint } from '../interface';
import { PrinterService } from '../services/printer.service';
import { FirebaseService } from '../services/firebase.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'printerconnection',
  templateUrl: './printerconnection.component.html',
  styleUrls: ['./printerconnection.component.scss']
})
export class PrinterConnectionComponent implements OnInit {
  @Output()
  configuredPrinter = new EventEmitter<ConfigPrint[]>();
  @Output()
  activeConnectorPrinter = new EventEmitter<boolean>();
  @Output()
  activeQz = new EventEmitter<boolean>();

  constructor(
    private printerService: PrinterService,
    private firebaseService: FirebaseService,
    private afAuth: AngularFireAuth
  ) {}
  printerActive: boolean = false;
  waitConnector: boolean = false;
  defaultPrinter: boolean = false;
  error: string;
  configList: ConfigPrint[];

  ngOnInit() {
    this.getConfig();
    if (this.printerService.isActive()) {
      this.getConfig();
      this.printerActive = true;
      this.activeQz.emit(true);
    } else {
      this.waitConnector = true;
      this.printerService.connect().then(
        () => {
          this.waitConnector = false;
          this.getConfig();
          this.printerActive = true;
          this.activeQz.emit(true);
        },
        err => {
          this.waitConnector = false;
          this.printerActive = false;
          this.error = err;
          this.activeConnectorPrinter.emit(false);
          this.activeQz.emit(false);
        }
      );
    }
  }
  getConfig() {
    this.firebaseService
      .getConfigList(this.afAuth.auth.currentUser.uid)
      .subscribe(cnf => {
        this.configList = cnf;
        this.defaultPrinter = this.checkDefaultPrinter(cnf);
        this.configuredPrinter.emit(cnf);
        this.activeConnectorPrinter.emit(this.defaultPrinter);
      });
  }
  checkDefaultPrinter(cnf: ConfigPrint[]): boolean {
    let configIndexDefault = cnf.findIndex(item => item.uid == 'default');
    return configIndexDefault != -1;
  }

  refresh() {
    location.reload();
  }
}
