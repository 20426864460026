import { TranslateService } from '@ngx-translate/core';
import { Element, ElementMens } from './../interface';
import { AngularFireAuth } from 'angularfire2/auth';
import { FirebaseService } from './../services/firebase.service';
import { PageTitleService } from './../core/page-title/page-title.service';
import { FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as _moment from 'moment';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatTableDataSource,
  MatPaginator,
  MatDatepicker
} from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import Utility from '../utility';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'ms-cassa',
  templateUrl: './cassa.component.html',
  styleUrls: ['./cassa.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class CassaComponent implements OnInit, AfterViewInit {
  date = new FormControl(moment());
  displayedColumns = [
    'data',
    'coperti',
    'carta',
    'contanti',
    'banco',
    'ccBanco',
    'totale'
  ];
  displayedColumnsMens = [
    'dataMens',
    'cartaMens',
    'contantiMens',
    'bancoMens',
    'ccBancoMens',
    'totaleMens'
  ];
  dataSource;
  dataSourceMens;

  @ViewChild('paginator')
  paginator: MatPaginator;
  @ViewChild('paginatorMens')
  paginatorMens: MatPaginator;

  constructor(
    private pageTitleService: PageTitleService,
    private firebaseService: FirebaseService,
    private afAuth: AngularFireAuth,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle(this.translate.instant('CASH_DESK'));
  }

  ngAfterViewInit() {
    this.getStatistiche();
    this.translate.onLangChange.subscribe(() => {
      this.pageTitleService.setTitle(this.translate.instant('CASH_DESK'));
      this.getStatistiche();
    });
  }

  getStatistiche() {
    this.firebaseService
      .getStatisticheCassa(this.afAuth.auth.currentUser.uid)
      .subscribe(cassa => {
        this.dataSource = new MatTableDataSource<Element>(cassa.reverse());
        this.dataSource.paginator = this.paginator;
        let cassaMens = Utility.getAggregationMonth(cassa);
        this.dataSourceMens = new MatTableDataSource<ElementMens>(cassaMens);
        this.dataSourceMens.paginator = this.paginatorMens;
        this.paginator._intl.itemsPerPageLabel = this.translate.instant(
          'CASSA.ROW_PAGE'
        );
        this.paginatorMens._intl.itemsPerPageLabel = this.translate.instant(
          'CASSA.ROW_PAGE'
        );
      });
  }

  applyFilter() {
    let momentDate = this.date.value;
    let filter = momentDate.format('DD.MM.YYYY');
    this.dataSource.filter = filter;
  }

  removeFilter() {
    this.dataSource.filter = '';
  }
}
