import { CassaFiscaleComponent } from './cassaFiscale/cassaFiscale.component';
import { BlackListComponent } from './chat/blacklist/blacklist.component';
import { DownloadpageComponent } from './downloadpage/downloadpage.component';
import { AbbonamentiComponent } from './abbonamenti/abbonamenti.component';
import { CassaComponent } from './cassa/cassa.component';
import { CategorieComponent } from './categorie/categorie.component';
import { ResetPasswordComponent } from './app-login/reset-password/reset-password.component';
import { LoginComponent } from './app-login/login/login.component';
import { Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AuthGuard } from './services/auth.service';
import { RevenueComponent } from './revenue/revenue.component';
import { ConfigPrintComponent } from './configprint/configprint.component';
import { ManageDetailTableComponent } from './managetable/manageDetailTable';
import { VenditeComponent } from './vendite/vendite.component';
import { EFatturaComponent } from './eFattura/efattura.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  { path: 'login', component: LoginComponent },
  { path: 'reset', component: ResetPasswordComponent },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'manageuser',
        loadChildren: './manageuser/manageuser.module#manageUserModule'
      },
      {
        path: 'managetable',
        loadChildren: './managetable/managetable.module#manageTableModule'
      },
      {
        path: 'managemenu',
        loadChildren: './managemenu/managemenu.module#manageMenuModule'
      },
      {
        path: 'promozioni',
        loadChildren: './promozioni/promozioni.module#PromozioniModule'
      },
      {
        path: 'cassaFiscale',
        component: CassaFiscaleComponent
      },
      {
        path: 'reports',
        children: [
          {
            path: 'cassa',
            component: CassaComponent
          },
          {
            path: 'revenue',
            component: RevenueComponent
          },
          {
            path: 'vendite',
            component: VenditeComponent
          },
          {
            path: 'efatture',
            component: EFatturaComponent
          }
        ]
      },
      {
        path: 'settings',
        children: [
          {
            path: 'configprint',
            component: ConfigPrintComponent
          },
          {
            path: 'download',
            component: DownloadpageComponent
          },
          {
            path: 'accountinfo',
            redirectTo: '/manageuser/user',
            pathMatch: 'full'
          },
          {
            path: 'myristo',
            redirectTo: '/manageuser/managecompany/1',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'abbonamenti',
        component: AbbonamentiComponent
      },
      {
        path: 'blacklist',
        component: BlackListComponent
      },
      {
        path: 'classification',
        component: CategorieComponent
      },
      {
        path: 'chat',
        loadChildren: './chat/chat.module#ChatModule'
      }
    ]
  }
];
