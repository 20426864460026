import { FirebaseService } from './../services/firebase.service';
import { PageTitleService } from './../core/page-title/page-title.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Abbonamento } from './abbonamento';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ms-abbonamenti',
  templateUrl: './abbonamenti.component.html',
  styleUrls: ['./abbonamenti.component.scss']
})
export class AbbonamentiComponent implements OnInit {
  dettAbbonamento: Abbonamento;
  abbonamentiList;
  constructor(
    private modalService: NgbModal,
    private pageTitleService: PageTitleService,
    private firebaseService: FirebaseService
  ) {}

  ngOnInit() {
    this.firebaseService
      .getAbbonamentiList()
      .subscribe(abb => (this.abbonamentiList = abb));
  }

  editAbbonamento(content, abbonamento) {
    if (abbonamento) this.dettAbbonamento = abbonamento;
    else this.dettAbbonamento = new Abbonamento();
    this.modalService.open(content).result.then(
      result => {
        if (result.key)
          this.firebaseService.updateAbbonamenti(result.key, result);
        else this.firebaseService.addAbbonamenti(result);
      }
    );
  }
}
