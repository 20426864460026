import { TranslateService } from '@ngx-translate/core';
import { Element, CompanyRevenue, Revenue, CompanyList } from './../interface';
import { AngularFireAuth } from 'angularfire2/auth';
import { FirebaseService } from './../services/firebase.service';
import { PageTitleService } from './../core/page-title/page-title.service';
import { FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as _moment from 'moment';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatTableDataSource,
  MatPaginator
} from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import Utility from '../utility';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'ms-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class RevenueComponent implements OnInit, AfterViewInit {
  date = new FormControl(moment());
  displayedColumns = [
    'ristorante',
    'coperti',
    'carta',
    'contanti',
    'banco',
    'ccBanco',
    'incasso',
    'revenueSala',
    'revenueBanco',
    'revenue',
    'payback'
  ];
  displayedColumnsDett = [
    'data',
    'coperti',
    'abbonamento',
    'carta',
    'contanti',
    'banco',
    'ccBanco',
    'totale',
    'revenueSala',
    'revenueBanco',
    'revenue',
    'payback'
  ];
  dataSource;
  dataSourceDett;
  companyList: CompanyList[];
  companyRevenue: CompanyRevenue[];
  dettaglio: string;

  @ViewChild('paginator')
  paginator: MatPaginator;
  @ViewChild('paginatorDett')
  paginatorDett: MatPaginator;
  currentLang: string;

  constructor(
    private pageTitleService: PageTitleService,
    private firebaseService: FirebaseService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle(this.translate.instant('REVENUE'));
    this.currentLang = this.translate.currentLang;
  }

  ngAfterViewInit() {
    this.getAbbForRev();
    this.translate.onLangChange.subscribe(() => {
      this.pageTitleService.setTitle(this.translate.instant('REVENUE'));
      this.currentLang = this.translate.currentLang;
      this.getAbbForRev();
    });
  }

  private getAbbForRev() {
    this.firebaseService
      .getAbbonamenti()
      .then(abb => this.getRevenue(abb.val()));
  }

  getRevenue(abb) {
    let crlist = new Array<CompanyRevenue>();
    let compList = new Array<CompanyList>();
    this.firebaseService.getCompanyList().then(snap => {
      let snapVal = snap.val();
      Object.keys(snapVal).forEach(element => {
        let res = snapVal[element];
        let company = {} as CompanyList;
        let cr = {} as CompanyRevenue;
        let listAbbonamemti = new Array<CompanyRevenue>();
        let statEl: Element[];
        if (res['listaPiani']) {
          res['listaPiani'].forEach(piano => {
            if (piano) {
              listAbbonamemti.push(
                this.setCompanyRevenue(abb[piano.piano], piano.inizioPiano)
              );
            }
          });
        }
        if (res['abbonamento'])
          listAbbonamemti.push(
            this.setCompanyRevenue(
              abb[res['abbonamento']],
              res['inizioAbbonamento']
            )
          );
        cr.uid = res['uid'];
        cr.name = res['nome'];
        cr.revBanco = res['comBanco'] ? 5 : 0;
        company.uid = res['uid'];
        company.name = res['nome'];
        this.firebaseService
          .getStatisticheCassa(company.uid)
          .subscribe(stat => {
            let tRev = 0;
            let bRev = 0;
            let totRev = 0;
            let cartaTot = 0;
            let contTot = 0;
            let bancoTot = 0;
            let ccBancoTot = 0;
            let incassoTot = 0;
            let copTot = 0;
            let paybTot = 0;
            let paybBancoTot = 0;
            cr.revenueList = new Array<Revenue>();
            if (stat) {
              statEl = stat;
              Utility.getAggregationMonth(statEl).forEach(r => {
                let revenue = {} as Revenue;
                let piano = this.getPianoPerMese(
                  listAbbonamemti,
                  _moment(r.dataMens, 'MM.YYYY').format('YYYYMM')
                );
                revenue.data = r.dataMens;
                revenue.abbonamento = piano.abbonamento;
                revenue.coperti = r.copertiMens;
                revenue.carta = r.cartaMens ? +r.cartaMens.toFixed(2) : 0;
                revenue.contanti = r.contantiMens
                  ? +r.contantiMens.toFixed(2)
                  : 0;
                revenue.banco = r.bancoMens ? +r.bancoMens.toFixed(2) : 0;
                revenue.ccBanco = r.ccBancoMens ? +r.ccBancoMens.toFixed(2) : 0;
                revenue.totale = r.totaleMens ? +r.totaleMens.toFixed(2) : 0;

                let fee =
                  r.copertiMens < 600
                    ? piano.coperto
                    : r.copertiMens < 1000
                    ? piano.coperto600
                    : r.copertiMens < 1500
                    ? piano.coperto1000
                    : piano.coperto1500;
                fee = fee ? fee : 0;
                let tot = r.totaleMens ? +r.totaleMens.toFixed(2) : 0;
                revenue.revenueSala =
                  fee > 0
                    ? (tot * fee) / 100
                    : piano.revCoperto * +revenue.coperti;
                revenue.revenueBanco =
                  (cr.revBanco * (+revenue.banco + +revenue.ccBanco)) / 100;
                revenue.revenue = +revenue.revenueSala + +revenue.revenueBanco;
                revenue.payback = revenue.carta - revenue.revenue;
                tRev += revenue.revenueSala;
                bRev += revenue.revenueBanco;
                totRev += revenue.revenueSala + revenue.revenueBanco;
                cartaTot += revenue.carta;
                contTot += revenue.contanti;
                bancoTot += revenue.banco;
                ccBancoTot += revenue.ccBanco;
                incassoTot += revenue.totale;
                copTot += +revenue.coperti;
                paybTot += revenue.payback;
                cr.revenueList.push(revenue);
              });
              cr.revenueSala = +tRev.toFixed(2);
              cr.revenueBanco = +bRev.toFixed(2);
              cr.revenueTotale = +totRev.toFixed(2);
              cr.contantiTotale = +contTot.toFixed(2);
              cr.cartaTotale = +cartaTot.toFixed(2);
              cr.bancoTotale = +bancoTot.toFixed(2);
              cr.ccBancoTotale = +ccBancoTot.toFixed(2);
              cr.incassoTotale = +incassoTot.toFixed(2);
              cr.copertiTotale = +copTot.toFixed(2);
              cr.paybackTotale = +paybTot.toFixed(2);
              crlist.push(cr);
              compList.push(company);
              this.dataSource = new MatTableDataSource<CompanyRevenue>(
                crlist.reverse()
              );
              this.dataSource.paginator = this.paginator;
              this.paginator._intl.itemsPerPageLabel = this.translate.instant(
                'CASSA.ROW_PAGE'
              );
            }
          });
        this.companyList = compList;
        this.companyRevenue = crlist;
      });
    });
  }

  getPianoPerMese(
    listaPiani: Array<CompanyRevenue>,
    dataMens: string
  ): CompanyRevenue {
    let i = listaPiani.findIndex(el => +dataMens == +el.inizioAbbonamento);
    if (i == -1) {
      let indice = 0;
      listaPiani.forEach(el => {
        if (+el.inizioAbbonamento < +dataMens) i = indice;
        indice++;
      });
    }
    return listaPiani[i];
  }

  private setCompanyRevenue(abb: any, inizio: string) {
    let cr = {} as CompanyRevenue;
    cr.abbonamento = abb.nome;
    cr.inizioAbbonamento = inizio;
    cr.revCoperto = abb.revCoperto;
    cr.coperto = abb.coperto;
    cr.coperto600 = abb.coperto600;
    cr.coperto1000 = abb.coperto1000;
    cr.coperto1500 = abb.coperto1500;
    return cr;
  }

  dettaglioRisto() {
    this.companyRevenue.forEach(rev => {
      if (rev.uid === this.dettaglio) {
        this.dataSourceDett = new MatTableDataSource<Revenue>(
          rev.revenueList.reverse()
        );
        this.dataSourceDett.paginator = this.paginatorDett;
        this.paginatorDett._intl.itemsPerPageLabel = this.translate.instant(
          'CASSA.ROW_PAGE'
        );
      }
    });
  }

  applyFilter() {
    let momentDate = this.date.value;
    let filter = momentDate.format('DD.MM.YYYY');
    this.dataSource.filter = filter;
  }

  removeFilter() {
    this.dataSource.filter = '';
  }
}
