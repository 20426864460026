import { DatiFatturazione } from 'app/eFattura/efattura.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _moment from 'moment';
import Utility from 'app/utility';
const url = 'https://fattura.gecotechsrl.it/fatturapa/interscambio/';

@Injectable()
export class EFatturaService {
  constructor(private http: HttpClient) {}

  public getDownloadPdfLink(user: string, piva: string, filename: string) {
    return (
      url +
      'downloadPdf?user=' +
      user +
      '&piva=' +
      piva +
      '&filename=' +
      filename
    );
  }
  public anteprimaFattura(
    progressivo: string,
    datiRisto: DatiFatturazione,
    datiCliente: DatiFatturazione,
    importoFattura: number
  ) {
    let xml = this.generateXml(
      progressivo,
      datiRisto,
      datiCliente,
      importoFattura
    );
    let b64xml = btoa(xml);
    return this.http
      .post(
        url + 'anteprimaFattura',
        {
          user: 'test',
          piva: '1234',
          fatturaB64: b64xml,
          progressivo: progressivo
        },
        { responseType: 'blob' }
      )
      .map(res => {
        return new Blob([res], { type: 'application/pdf' });
      });
  }

  public addInvoice(
    progressivo: string,
    datiRisto: DatiFatturazione,
    datiCliente: DatiFatturazione,
    importoFattura: number
  ) {
    let xml = this.generateXml(
      progressivo,
      datiRisto,
      datiCliente,
      importoFattura
    );
    let b64xml = btoa(xml);
    return this.http.post(
      url + 'addInvoiceTest',
      {
        user: datiRisto.userFatturaPA,
        piva: datiRisto.partitaIva,
        fatturaB64: b64xml,
        progressivo: progressivo
      },
      { responseType: 'json' }
    );
  }

  public getFattureIn(user: string, piva: string) {
    return this.http.get(url + 'lista/in?user=' + user + '&piva=' + piva);
  }
  public getFattureOut(user: string, piva: string) {
    return this.http.get(url + 'lista/out?user=' + user + '&piva=' + piva);
  }
  private generateXml(
    progressivo: string,
    datiRisto: DatiFatturazione,
    datiCliente: DatiFatturazione,
    importoFattura: number
  ) {
    let xmlFatt =
      '<?xml version="1.0" encoding="UTF-8"?>' +
      '<p:FatturaElettronica xmlns:p="http://ivaservizi.agenziaentrate.gov.it/docs/xsd/fatture/v1.2" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" versione="FPR12">';
    xmlFatt += this.generaHeaderXml(progressivo, datiRisto, datiCliente);

    xmlFatt += this.generaBodyXml(progressivo, importoFattura);
    xmlFatt += '</p:FatturaElettronica>';
    return xmlFatt;
  }
  private generaHeaderXml(
    progressivo: string,
    datiRisto: DatiFatturazione,
    datiCliente: DatiFatturazione
  ) {
    let header =
      '<FatturaElettronicaHeader>' +
      '<DatiTrasmissione>' +
      '<IdTrasmittente>' +
      '<IdPaese>IT</IdPaese>' +
      '<IdCodice>01879020517</IdCodice>' +
      '</IdTrasmittente>' +
      '<ProgressivoInvio>' +
      progressivo +
      '</ProgressivoInvio>' +
      '<FormatoTrasmissione>FPR12</FormatoTrasmissione>' +
      '<CodiceDestinatario>' +
      datiCliente.codiceUnivoco +
      '</CodiceDestinatario>' +
      '</DatiTrasmissione>' +
      '<CedentePrestatore>' +
      '<DatiAnagrafici>' +
      '<IdFiscaleIVA>' +
      '<IdPaese>' +
      datiRisto.nazione +
      '</IdPaese>' +
      '<IdCodice>' +
      datiRisto.partitaIva +
      '</IdCodice>' +
      '</IdFiscaleIVA>' +
      '<Anagrafica>' +
      '<Denominazione>' +
      datiRisto.denominazione +
      '</Denominazione>' +
      '</Anagrafica>' +
      '<RegimeFiscale>' +
      datiRisto.regimeFiscale +
      '</RegimeFiscale>' +
      '</DatiAnagrafici>' +
      '<Sede>' +
      '<Indirizzo>' +
      datiRisto.indirizzo +
      '</Indirizzo>' +
      '<NumeroCivico>' +
      datiRisto.numeroCivico +
      '</NumeroCivico>' +
      '<CAP>' +
      datiRisto.cap +
      '</CAP>' +
      '<Comune>' +
      datiRisto.comune +
      '</Comune>' +
      '<Provincia>' +
      datiRisto.provincia +
      '</Provincia>' +
      '<Nazione>' +
      datiRisto.nazioneSocieta +
      '</Nazione>' +
      '</Sede>' +
      '</CedentePrestatore>' +
      '<CessionarioCommittente>' +
      '<DatiAnagrafici>' +
      '<IdFiscaleIVA>' +
      '<IdPaese>' +
      datiCliente.nazione +
      '</IdPaese>' +
      '<IdCodice>' +
      datiCliente.partitaIva +
      '</IdCodice>' +
      '</IdFiscaleIVA>' +
      '<CodiceFiscale>' +
      datiCliente.codiceFiscale +
      '</CodiceFiscale>' +
      '<Anagrafica>' +
      '<Denominazione>' +
      datiCliente.denominazione +
      '</Denominazione>' +
      '</Anagrafica>' +
      '</DatiAnagrafici>' +
      '<Sede>' +
      '<Indirizzo>' +
      datiCliente.indirizzo +
      '</Indirizzo>' +
      '<CAP>' +
      datiCliente.cap +
      '</CAP>' +
      '<Comune>' +
      datiCliente.comune +
      '</Comune>' +
      '<Provincia>' +
      datiCliente.provincia +
      '</Provincia>' +
      '<Nazione>' +
      datiCliente.nazioneSocieta +
      '</Nazione>' +
      '</Sede>' +
      '</CessionarioCommittente>' +
      '</FatturaElettronicaHeader>';

    return header;
  }
  private generaBodyXml(progressivo: string, importoFattura: number) {
    let importoTotale = importoFattura.toFixed(2);
    let imponibile = (importoFattura / 1.1).toFixed(2);
    let imposta = (+importoTotale - +imponibile).toFixed(2);
    let body =
      '<FatturaElettronicaBody>' +
      '<DatiGenerali>' +
      '<DatiGeneraliDocumento>' +
      '<TipoDocumento>TD01</TipoDocumento>' +
      '<Divisa>EUR</Divisa>' +
      '<Data>' +
      _moment().format('YYYY-MM-DD') +
      '</Data>' +
      '<Numero>' +
      progressivo +
      '</Numero>' +
      '<ImportoTotaleDocumento>' +
      importoTotale +
      '</ImportoTotaleDocumento>' +
      '<Causale>Fattura</Causale>' +
      '</DatiGeneraliDocumento>' +
      '</DatiGenerali>' +
      '<DatiBeniServizi>' +
      '<DettaglioLinee>' +
      '<NumeroLinea>1</NumeroLinea>' +
      '<Descrizione>PASTO COMPLETO</Descrizione>' +
      '<Quantita>1.00</Quantita>' +
      '<PrezzoUnitario>' +
      imponibile +
      '</PrezzoUnitario>' +
      '<PrezzoTotale>' +
      imponibile +
      '</PrezzoTotale>' +
      '<AliquotaIVA>10.00</AliquotaIVA>' +
      '</DettaglioLinee>' +
      '<DatiRiepilogo>' +
      '<AliquotaIVA>10.00</AliquotaIVA>' +
      '<ImponibileImporto>' +
      imponibile +
      '</ImponibileImporto>' +
      '<Imposta>' +
      imposta +
      '</Imposta>' +
      '<EsigibilitaIVA>I</EsigibilitaIVA>' +
      '</DatiRiepilogo>' +
      '</DatiBeniServizi>' +
      '</FatturaElettronicaBody>';
    return body;
  }
}
